import {
  AppMode, LayerInfo, 
  ViewerState, MapInfo, OtherLegendSpec, 
  LayerHierarchy,
  LayerVariabilityType,
  MapCommand,
} from '../common/managers/Types';
import {PersistenceObjectType, PersistenceScope, SessionContext, Utils, ViamapPersistenceLayer} from '@viamap/viamap2-common';
import {Cookies, MitCookies} from '@viamap/viamap2-common';

import {Localization, SettingsManager} from "@viamap/viamap2-common";
import {GenerateGeom} from '../managers/GenerateGeom';
import {Logger} from '@viamap/viamap2-common';
import {Persistence} from '../managers/Persistence';
import {CatchmentInterface} from '../managers/CatchmentInterface';
import {LayerFunc} from '../managers/LayerFunc';
import Catchment from '../svg/catchment/catchment.svg?react'

import {Legend} from './Legend';
import {ReadOnlyMapDialog} from './ReadOnlyMapDialog';

import {RandomColorRGBA} from './ComponentUtils';

import {CustomerLogo} from './CustomerLogo';
import {Image} from 'react-bootstrap';

import { PropertyInfoInterface } from '../managers/PropertyInfoInterface';

import { FileImportContext } from './FileImporter';
import { FileImportType } from "./FileImportHooks";
import CookieApproval from './CookieApproval';
import { MapFacadeFactory, MitEvent, MitLatLng, MitLayerHandle, MitMap } from 'src/managers/MapFacade';
import { actionAddDataLayer, actionRemoveDataLayer, actionSetFeatureLayerVisibility, MapInteractionState, MapitStateActionType, MapitStateContext, actionSetViewPoi, actionAddPopup, MitPopup, actionSetMapInteractionStateDefault, actionClosePopups, actionSetMapInteractionState, DataDrivenBackgroundLayer } from 'src/states/MapitState';
import { useContext, useEffect, useRef, useState } from 'react';
import { actionClearInfoMessage, actionSetErrorMessage, actionSetInfoMessage, AppMessagesContext } from '@viamap/viamap2-common';
import { AddressInterface } from 'src/managers/AddressInterface';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';
import { AreaPopup, LinePopup, PointInfoPopup, PointPopup, VectorLayerPopup } from './MapPopup';
import { LegendScaleForCatchment } from './LegendScaleForCatchment';
import { FeatureLayer, FeatureLayerList } from 'src/managers/WmsLayerFunc';
import { BsExclamationTriangleFill, BsLayers, BsPerson, BsPersonWalking } from 'react-icons/bs';
import { CatchmentStateContext, actionGenerateCatchmentSimple } from 'src/states/CatchmentState';
import { MitStorage } from 'src/managers/MitStorage';
import { DynamicDataDrivenLayerFunc } from 'src/managers/DynamicDataDrivenLayer';
import { VectorLayer } from 'src/managers/VectorLayerFunc';
import { executeCommand } from 'src/managers/RestoreMapLink';
import { MdLegendToggle } from 'react-icons/md';
import { createPortal } from 'react-dom';
import { FaWalking } from 'react-icons/fa';
import { Point } from 'maplibre-gl';
import { GeojsonWFS } from 'src/managers/WFSGeojsonLayerFunc';
import { useWindowContext } from 'src/WindowManager/useWindowContext';
import { closeWindow, openWindow, WindowId } from 'src/WindowManager/WindowState';

type State= {
  showLayerManager: boolean;
  // showLegend: boolean;
  showNewFeatures: boolean;
  showReleases: boolean;
  featuresInRelease: string | boolean;
  showAdminWindow?: boolean;
  showSaveMapDialog?: boolean;
  showSaveMapLinkDialog?:boolean;
  saveMapLinkDialogDefault?:number;
  showGenerateReportDialog?:boolean;
  showGenerateReportDialogEsri?:boolean;
  showGenerateReportResultDialog?:boolean;
  showSpatialExportDialog?:boolean;
  showMapStylingWindow?:boolean;
  generateReportResults?:any,
  generateReportMetaData?:any,
  streetToken?:any,
  layerPopInfo: {pData:any,lData:{[layerName:string]:any}} | null;
  colorRanges: any[];
  callbackToLogout: () => any;
  poiLayer?: MitLayerHandle;
  zoomLevel?: number;
  showSaveLinkWindow?:boolean;
  gettingStarted:boolean;
  saveLinkUrl?:string;
  saveLinkEmbedCode?:string;
  saveLinkExpireDate?:Date;
  currentLocationControl?: MitLayerHandle;
  showTrialSignupDialog:boolean; // not implemented yet
  callBackOnTrialSignup:(name:string, company:string, phoneNo:string, termsApproved:boolean)=>void;
  showGettingStartedDialog:boolean;
  showObliqueViewer?:boolean;
  labelLatLng?:{latlng:MitLatLng};
  poitypeList?:string[];
  showGDPRNoticeApprovalDialog?:boolean;
  showCookieAcceptDialog?:boolean;
  showConfirmationDialog: boolean;
  currentGDPRparameter?: any;
  currentGDPRcookie?: MitCookies;
  showStreetView: boolean;
  showEjerlavSearchBar: boolean;
  measure: undefined;
  showMeasurementOptions: boolean;
  mapOnClickActive: boolean;
  featureLayerPopupsShouldBeDisabled: boolean;
};

type Props = {
  callbackToLogout: () => any;
  showTrialSignupDialog:boolean;
  callBackOnTrialSignup:(name:string, company:string, phoneNo:string, termsApproved:boolean)=>void;
  callBackMap: (a:any) => any;
  mapReference?: string
  commandList?: MapCommand[]
};

export type LayerChangeAction =
  | "Add"
  | "Remove"
  | "Update"
  | "Styling"
  | "Toggle"
  | "MetaData"
  | "Hide"
  | "Show"

export const MapScreen = (props:Props) => {
  const {dispatch:appMessageDispatch} = useContext(AppMessagesContext);
  const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
  const { importState: fileState, importCallBack: fileDipatch } = useContext(FileImportContext);
  const {state:sessionState} = useContext(SessionContext);
  const { state: applicationState, dispatch: applicationDispatch, hasAccessToFeature } = useContext(ApplicationStateContext);
  const {state:catchmentState, dispatch:catchmentStateDispatch} = useContext(CatchmentStateContext);
  const poiRef = useRef<number|null>(null);
  const {state:windowState, dispatch:windowDispatch} = useWindowContext()
  
  const [openFeaturLayer, setOpenFeatureLayers] = useState<string[]>([]);

  const mapContainer = useRef<HTMLDivElement>(null);

  const [streetViewLngLat, setStreetViewLngLat] = useState<{lat:number, lng:number} | null>(null);
  const [layerToStyle, setLayerToStyle] = useState<number | null>(null);

  const [hierarchyToStyle, setHierarchyToStyle] = useState(null);

  
  let _instance;
  

  function callbackOnLayerChangesStatic(action:LayerChangeAction, layerInfo:LayerInfo, statCallback?:(info:any)=>void) {
    _instance.callbackOnLayerChanges(action, layerInfo, statCallback);
  }

  function setState(ss:any) {
    // setXState({...state, ...ss});
  }

  let width = document.body.clientWidth;
  let smallDevice = width <= 992;
  
  
  useEffect(() => {
    if (mapContainer.current) {


    let map: MitMap = MapFacadeFactory.getMapFacade(mapContainer.current);
    mapitStateDispatch({type:MapitStateActionType.SetMapObjectRef, payload:{item:map as any}});
    map.registerMoveendCallbackHandler((viewerState:ViewerState) => {
      mapitStateDispatch({type:MapitStateActionType.SetMapViewerState, payload:{state:viewerState}});
    })
  
      // GENERAL ERROR HANDLER
      document.body.addEventListener(
        'mit-error-handler', 
        (e:any) => { 
          if (e.detail) {
            e.detail && Logger.logError("MapScreen", "mit-error-handler", e.detail);
          }
          appMessageDispatch(actionSetErrorMessage(e.detail));
        }
      );
  
      // GENERAL INFO MESSAGE HANDLER
      document.body.addEventListener(
        'mit-info-handler', 
        (e:any) => {
          if (e.detail) {
            e.detail && Logger.logInfo("MapScreen", "mit-info-handler", e.detail);
          }
          appMessageDispatch(actionSetInfoMessage(e.detail));
        }
      );
  
      GenerateGeom.setupBaseData(map);
      mapitStateDispatch({type:MapitStateActionType.InitializeStandardLayers, payload:{hasAccessToFeatureFunction:hasAccessToFeature}});
      props.callBackMap(map);
      
  
      // Warn user before closing the window - unless in special modes
      if (!(
        Utils.isDevelopmentBuild() ||
        Utils.isTestBuild() ||
        applicationState.appMode === AppMode.ReadOnly ||
        applicationState.appMode === AppMode.Embedded
        )) {
        // If in normal mode: enable warning
        window.onbeforeunload = closeIt;
      }

      map.on('move', () => {
        const popBox = document.getElementById("FillExtrusionDetail")
        popBox && (popBox.style.display = "none")
      })

      map.on('moveend', () => {
        if (map.getZoom() >= SettingsManager.getSystemSetting("MaxZoomPoi", 12, true) !== mapitStateRef.current.viewPoi) {
          mapitStateDispatch(actionSetViewPoi(map.getZoom() >= SettingsManager.getSystemSetting("MaxZoomPoi", 12, true)))
        }
        updateDataForVisibleArea();
      })
      map.on('load', () => {
        updateDataForVisibleArea();
        console.info(">> done <<")
      })
      map.once('load', () => {
        false && hasAccessToFeature(Feature.ViewCustomFeatureLayers) && loadCustomFeatureLayers();
        let featureL = mapitStateRef.current.featureLayers;
        let defautlLayers = Object.keys(featureL).filter((a) => featureL[a].layer.activeByDefault).map((a) => featureL[a].layer.label)
        let rememberedLayers = SettingsManager.getSystemSetting("SaveOpendedFeatureLayers") && MitStorage.getValue<[]>("MIT-SelectedLayers") || [];
        ([...defautlLayers, ...rememberedLayers]).forEach((layerKey) => {
          mapitStateDispatch(actionSetFeatureLayerVisibility(layerKey, true, [{key:layerKey}]));
        })
        mapitStateDispatch({type: MapitStateActionType.Restore})
        if (props.commandList?.length) {
          executeCommand(props.commandList, (a:LayerChangeAction,b:LayerInfo,c:boolean) => {
            switch (a) {
              case "Add":
                mapitStateDispatch(actionAddDataLayer({...b, readonly:true}, c))
                break;
            
              default:
                console.error("Unknown LayerChangeAction")
                break;
            }
          })
        }
      })
      
      if (props.mapReference && !props.commandList?.length) {
        fileDipatch({
          fileImportType: FileImportType.FileFetch,
          url: props.mapReference,
        })
      }
      
      // Control should Match position of Mit-MapOverlay
      const matchMitMapOverlay = () => {
        if (mapContainer.current) {
          let control = [...mapContainer.current.getElementsByClassName("maplibregl-control-container")][0] as HTMLDivElement
          let overlay = document.getElementById("Mit-NonBlockingOverlay")
          if (control && overlay) {
            const {top, left, width, height} = overlay.getBoundingClientRect()
            control.style.position = "absolute"
            control.style.height = height+"px"
            control.style.left = left+"px"
            control.style.width = width+"px"
            control.style.top = top+"px"
          }
        }
      }
      const resizeObserver = new ResizeObserver(matchMitMapOverlay)
      const overlay = document.getElementById("Mit-MapOverlay")
      if (overlay) {
        resizeObserver.observe(overlay)
      }
      matchMitMapOverlay()

      }
      
      }, []);
      
      let poiTypesToShowRef = useRef(mapitState.poiTypesToShow);
      let mapitStateRef = useRef(mapitState);
      useEffect(() => {
      mapitStateRef.current = mapitState;
      }, [mapitState]);
      
      useEffect(() => {
      if (mapitStateRef.current.map) {
        updateDataForVisibleArea();
      }


    }, [mapitState.poiTypesToShow])

    useEffect(() => {
      mapitStateDispatch({type:MapitStateActionType.InitializeStandardLayers, payload:{hasAccessToFeatureFunction:hasAccessToFeature}});
    },[applicationState.availableFeatures])

    useEffect(() => {
      let map = mapitState.map as any
      if (map == 1 || map == undefined) {
        return
      }

      map.on('click', onMapClick);
      
      const delayedStart = (e) => {
        if (e.defaultPrevented) {
          return
        }
        const preventTouchOnMove = () => {
          map.off('touchend', onMapClick)
          map.off('move', preventTouchOnMove)
        }
        map.on('touchend', onMapClick)
        map.on('move', preventTouchOnMove)
        setTimeout(() => {
          map.off('touchend', onMapClick)
        }, 200)
      }
      map.on('touchstart', delayedStart);

      map.on('mousemove', "poi-unclustered-icon" ,(e) => {
        
        if (poiRef.current !== null) {
          map.setFeatureState({source:e.features[0].source, id: poiRef.current}, {hover:false})
        }
        if (e.features.length > 0) {
          map.setFeatureState({source:e.features[0].source, id: e.features[0].id}, {hover:true})
          poiRef.current = e.features[0].id
        }
      })
      map.on('touchstart', "poi-unclustered-icon", (e) => {
        e.preventDefault()
        if (poiRef.current !== null) {
          map.setFeatureState({source:e.features[0].source, id: poiRef.current}, {hover:false})
        }
        if (e.features.length > 0) {
          map.setFeatureState({source:e.features[0].source, id: e.features[0].id}, {hover:true})
          poiRef.current = e.features[0].id
        }
      })
      map.on('mouseleave', "poi-unclustered-icon", (e) => {
        if (poiRef.current !== null) {
          map.setFeatureState({source:"poi-source-1", id: poiRef.current}, {hover:false})
          poiRef.current = null
        }
      })

      return () => {
        map.off('click', onMapClick)
        map.off('touchstart', delayedStart)
      }


    },[hasAccessToFeature, mapitState.map])

    useEffect(() => {
      let map = mapitState.map as any
      if (map == 1 || map == undefined) {
        return
      }
      if (mapitState.selectedFeatureLayerKeys.length && (mapitState.mapInteractionState === MapInteractionState.ZoomedIn || mapitState.mapInteractionState === MapInteractionState.NSZoomedIn)) {
        mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Normal))
        return
      }
      if (mapitState.selectedFeatureLayerKeys.length) {
        return
      }

      function MoveZoomedInd() {
        if (mapitState.mapInteractionState === MapInteractionState.Normal) {
          if (map.getZoom() > SettingsManager.getSystemSetting("mapInteractionStateZoomedIn")) {
            if (hasAccessToFeature(Feature.ThemeNewSec)) {
              mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.NSZoomedIn))
            } else {
              mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.ZoomedIn))
            }
          }
        } else if (mapitState.mapInteractionState === MapInteractionState.ZoomedIn) {
          if (map.getZoom() < SettingsManager.getSystemSetting("mapInteractionStateZoomedIn")) {
            mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Normal))
          }
        } else if (mapitState.mapInteractionState === MapInteractionState.NSZoomedIn) {
          if (map.getZoom() < SettingsManager.getSystemSetting("mapInteractionStateZoomedIn")) {
            mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Normal))
          }
        }
      }
      map.on('moveend', MoveZoomedInd)
      return () => map.off('moveend', MoveZoomedInd)
    },[mapitState.map, mapitState.mapInteractionState, mapitState.selectedFeatureLayerKeys])

    useEffect(() => {
      let urlToLabels = Object.values(mapitState.featureLayers).map((a) => ({
        urlIdent: a.layer.host + "|" + a.layer.layers,
        label: a.layer?.translationTable ? Localization.getTextSpecificTable(a.layer.label, a.layer?.translationTable) : a.layer.label,
        x:Math.random(), y:Math.random()
      }))
      mapitState.map?.setUrlToLabels?.(urlToLabels);
    },[mapitState.map, mapitState.featureLayers])

    

    useEffect(() => {
      let registeretEvents:{a:string,b:any,type:string}[] = []
      if (mapitState.mapInteractionState === MapInteractionState.EnforceFeatureLayerPopup) {
        mapitState.map.getCanvas().style.cursor = "crosshair"
        return () => {
          mapitState.map.getCanvas().style.cursor = ""
        }
      } else if (mapitState.mapInteractionState === MapInteractionState.Normal) {
        function clickDot(e, layerInfo) {
          DataLayerClickFunction(e, mapitState.mapInfo, layerInfo, (b) => mapitStateDispatch(actionAddPopup(MitLatLng.fromM(e.lngLat), (b), true)))
        }

        const vectorLayers = mapitState.selectedFeatureLayerKeys.filter((a) => {
          return (mapitState.featureLayers[a] instanceof VectorLayer || mapitState.featureLayers[a] instanceof GeojsonWFS)
        }).map((a) => {return {id: (mapitState.featureLayers[a] as VectorLayer).getLayerID(0)}})
        const layerList = Object.keys(mapitState.layers).flatMap((layerID) => {
          const layerInfo = mapitState.layers[layerID]
          if (layerInfo?.handle?.layerList) {
            return [...layerInfo.handle.layerList]
          }
        });

        [...layerList || [],...vectorLayers || []].filter((a) => a.id).forEach(element => {


              function enterHandler(e) {
                e.target.getCanvasContainer().style.cursor = "pointer"
              }
              function leaveHandler(e) {
                e.target.getCanvasContainer().style.cursor = ""
                if (element.id.includes("fill-extrusion")) {
                  const popBox = document.getElementById("FillExtrusionDetail")
                  popBox && (popBox.style.display = "none")
                }
              }

              function mouseMove(e) {

                function LayerInfoFromSourceID(sourceId: string) {
                  return Object.values(mapitStateRef.current.layers).find((layerInfo) =>
                    layerInfo.handle.sourceId == sourceId
                  )
                }

                let layerId = LayerInfoFromSourceID(e.features[0].source)
                let props = e.features[0].properties
                let display = layerId?.propertiesToDisplay



                const div = document.createElement("div");
                div.className = "propsList";

                display?.map((a) => a.name).forEach((a) => {
                  if (props[a]) {

                    const line = document.createElement("div")
                    const val =  document.createElement("div")
                    const key =  document.createElement("div")
                    key.innerText = a
                    val.innerText = props[a]
                    line.appendChild(key)
                    line.appendChild(val)
                    div.appendChild(line)
                  }
                })

                const ev = (e.originalEvent as MouseEvent)
                const {x, y} = e.point as {x:number, y:number}

                const popBox = document.getElementById("FillExtrusionDetail")
                popBox && (popBox.innerHTML = "");
                popBox?.appendChild(div)
                popBox && (popBox.style.top = y + "px");
                popBox && (popBox.style.left = x + "px");
                popBox && (popBox.style.display = "block");
              }
              if (element.id.includes("fill-extrusion")) {
                mapitState.map.on('mousemove', element.id, mouseMove)
                registeretEvents.push({a:element.id, b:mouseMove, type:'mousemove'})
              }

              mapitState.map.on('mouseenter', element.id, enterHandler)
              registeretEvents.push({a:element.id, b:enterHandler, type:'mouseenter'})

              mapitState.map.on('mouseleave', element.id, leaveHandler)
              registeretEvents.push({a:element.id, b:leaveHandler, type:'mouseleave'})
            });
        return () => {registeretEvents.map(a => mapitState.map.off(a.type,a.a,a.b))}
      } else {
        mapitState.map.getCanvas().style.cursor = "pointer"
        return () => {
          mapitState.map.getCanvas().style.cursor = ""
        }
      }
    }, [mapitState.layers, mapitState.selectedFeatureLayerKeys , mapitState.mapInteractionState, mapitState.mapInfo])

    let mapInteractionStateRef = useRef(mapitState.mapInteractionState);
    useEffect(() => {
      mapInteractionStateRef.current = mapitState.mapInteractionState
    }, [mapitState.mapInteractionState])

    function updateDataForVisibleArea() {
      // Refresh any POIs
      GenerateGeom.AsyncDisplayPOIforBounds(mapitStateRef.current.map, mapitStateRef.current.poiLayerHandle, mapitStateRef.current.poiTypesToShow);

      // Refresh any (other) data driven layers
      if (mapitStateRef.current.map && (typeof mapitStateRef.current.map === 'object') && mapitStateRef.current.map.isInitialized()) {
        if (mapitStateRef.current.EsDrivenLayers && mapitStateRef.current.EsDrivenLayers.length > 0) {
          mapitStateRef.current.EsDrivenLayers.forEach(async (ddl:DataDrivenBackgroundLayer) => {
            (new DynamicDataDrivenLayerFunc()).OnMoveHandler(mapitStateRef.current.map, ddl.onMove);
            return
          })
        }
      }
    }

    
  /**
   * Loads all the custom feature layers stored in the user's domain's AWS configuration
   */
  async function loadCustomFeatureLayers(): Promise<void> {
    let domainName = sessionState.customerRef;
    if (domainName === "" || Object.values(mapitState.layers).filter((a) => a.layerVariability == LayerVariabilityType.FixedFeatureLayer).length) {
      return
    }

    let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
    // let prefixPath = "customerConfig/" + domainName + "/customLayers/";
    let prefixPath = persit.createPrefix(PersistenceScope.Customer, PersistenceObjectType.SavedMaps, domainName)
    let filesInFolder = await persit.getListingS3(prefixPath)
    filesInFolder.filter((a) => {
      return a.Key.includes(".mapit") || a.Key.includes(".estateExplorer")
    }).forEach(async (fileObj) => {
      let FileData = await persit.getObjectS3(fileObj.Key)
      let CurFeatureL = JSON.parse(FileData.Body.toString())
      if (CurFeatureL.mapSettings?.template) {
        return
      }
      let layerRenumberingMap: {[key:number]:number} = {};
      (CurFeatureL.layers as LayerInfo[]).forEach(layer => {
          let createdID = Math.round(Math.random() * 10000000) + 1000;
          layerRenumberingMap[layer.layerId] = createdID;
      });
      let newLayer = (CurFeatureL.layerHierarchy && {...CurFeatureL.layerHierarchy}) || {
        id: 0,
        name: "Top Level",
        isCollapsed: false,
        children: Object.keys(layerRenumberingMap).map((a) => ({id: a,children:[]}))
      }
      let newLayerHierachy = {...newLayer, children:[{readonly:true, id:-10000,isCollapsed:true, name:(CurFeatureL.mapInfo?.mapTitle || CurFeatureL.awsFilename),children: newLayer.children}]};
      let groupIdObject = GetFlatGroupFrom(newLayerHierachy,[]);
      await Persistence.createLayersFromJSON(
        CurFeatureL,
        new Date(),
        (...x) => {return},
        (...x) => {return},
        (layer) => {
          let obj:LayerInfo = {
            ...layer,
            group : [(layer.awsFilename ?? fileObj.Key.split("/").at(-1))].concat(groupIdObject[layer.layerId]).filter((a) => a),
            layerId : layerRenumberingMap[layer.layerId],
            readonly : true,
            layerVariability : LayerVariabilityType.FixedFeatureLayer,
            visible : false,
          }
          addALayer(mapitState.map, obj, false);
          return layer.layerId
        },
        (...x) => {return}
      )
    })

  }

  function ZoomPointViewAble(lng,lat) {
    if (
      window.innerWidth > 500 ||
      window.innerHeight > 800
    ) {
      return
    }

    const map = mapitState.map.getMapPop();
    let overlay = document.getElementById("Mit-NonBlockingOverlay") as HTMLDivElement
    let extraTopping = parseInt(window.getComputedStyle(overlay).getPropertyValue("--NonBlockTopSpace"))
    const wWidth = window.innerWidth;
    const wHeight = window.innerHeight;
    const {left, width, top, height} = overlay.getBoundingClientRect();

    const right = wWidth - (width + left)
    const horizontalOffset = (left - right) / 2;


    // Magic numbers, [PopupHeader, PopupTip, PopupOffset, NavigationBar]
    const verticalOffset = (wHeight - height) - (30 + 10 + 10 + extraTopping)
    
    let {x,y} = map.project([lng,lat])


    let pLngLat = map.unproject(new Point(x-horizontalOffset, y+verticalOffset))

    map.flyTo({
      center: pLngLat,
      curve: 1,
      speed: 1,
    });
  }

  function GetFlatGroupFrom(hierarchy: LayerHierarchy,curentIndex: string[]):{[layerId: number]:string[]} {
    let result:{[layerId: number]:string[]} = {}
    if (hierarchy?.children?.length == undefined ||  hierarchy.children.length === 0) {
      return {[hierarchy.id]: curentIndex}
    } else {
      (hierarchy.children as LayerHierarchy[]).forEach((child: LayerHierarchy) => {
        result = {...result, ...GetFlatGroupFrom(child, [...curentIndex,child.name])}
      });
    }
    return result
  }

    type MitEventExtended = MitEvent & {
      preventDefault: () => void
    }
    async function onMapClick(e: MitEventExtended) {
      if (mapitState.map) {
        mapitStateDispatch(actionClosePopups(true))
        switch (mapInteractionStateRef.current) {
          case MapInteractionState.ClickToSetMarker: {
            if (!e.originalEvent.ctrlKey)
            mapitStateDispatch(actionSetMapInteractionStateDefault());
            if (hasAccessToFeature(Feature.ThemeNewSec)) {
              let latLng = new MitLatLng(e.lngLat.lat, e.lngLat.lng)
              let resp = await AddressInterface.reverseGeocode(e.lngLat.lat,e.lngLat.lng);
              let addrData = await resp.json();
              let fullAddress = addrData.betegnelse;
              let tmp = fullAddress.split(',');
              let streetAndNumber = tmp[0] || fullAddress;
              let layerInfo = LayerFunc.createLayerInfoForOnePoint(latLng, streetAndNumber);
              mapitStateDispatch(actionAddDataLayer(layerInfo, false, true));
              DataLayerClickFunction({...e, features:[{geometry:{type:"Point"}}]}, mapitState.mapInfo, layerInfo, (b) => mapitStateDispatch(actionAddPopup(MitLatLng.fromM(e.lngLat), (b), true)))
            } else {
              let resp = await AddressInterface.reverseGeocode(e.lngLat.lat,e.lngLat.lng)
              let addrData = await resp.json();

              let name = Localization.getText("New Point")
              let data = {};
              if (resp.status == 200 && addrData) {
                let fullAddress = addrData.betegnelse;
                let tmp = fullAddress.split(',');
                let streetAndNumber = tmp[0] || fullAddress;
                let zipAndCity = addrData.postnr+" "+addrData.postnrnavn;
                let utmCoords = GenerateGeom.convertFromLatLngToUTM({...e.lngLat});
                let matData = (await PropertyInfoInterface.getMatrikelData(utmCoords))?.features[0]?.properties;
                data = matData.matrikelnummer && {"Navn":streetAndNumber, "Postnummer": zipAndCity, "Kommunekode": addrData.kommunekode}
                name = (matData.matrikelnummer && streetAndNumber) || name
              }
              
              let layerInfo = LayerFunc.createLayerInfoForOnePoint(new MitLatLng(e.lngLat.lat, e.lngLat.lng), name, data);
              mapitStateDispatch(actionAddDataLayer(layerInfo, false, true));
              // revert to inactive mode after the first point is clicked
            }
            break;
          }
          case MapInteractionState.ClickToShowObliquePhotos: {
            windowDispatch(openWindow(WindowId.ObliqueViewer, {latlng:new MitLatLng(e.lngLat.lat, e.lngLat.lng)}))
            break;
          }
          case MapInteractionState.ClickToShowPropertyInfo: {
            windowDispatch(openWindow(WindowId.PropertyInformationDialog, {latlng:new MitLatLng(e.lngLat.lat, e.lngLat.lng)}));
            break;
          }
          case MapInteractionState.ClickToShowStreetView: {
            windowDispatch(openWindow(WindowId.StreetView, e.lngLat));
            break;
          }
          case MapInteractionState.ClickToShowTravelTimePolygons: {
            CatchmentInterface.clearCache(); // clear cache as new point is selected
            catchmentStateDispatch(actionGenerateCatchmentSimple(catchmentState, mapitStateRef.current, new MitLatLng(e.lngLat.lat, e.lngLat.lng)));
            windowDispatch(openWindow(WindowId.CatchmentSelector, {latlng:new MitLatLng(e.lngLat.lat, e.lngLat.lng)}));
            windowDispatch(closeWindow(WindowId.PointDistanceTable));
            break;
          }
          case MapInteractionState.ClickToShowPointDistanceTable: {
            windowDispatch(openWindow(WindowId.PointDistanceTable, {dialogMode:"PointToPoint", latlng:new MitLatLng(e.lngLat.lat, e.lngLat.lng)}));
            break;
          }

          case MapInteractionState.HentVurderingsEjendom: {
            visVurderingsEjendom(MitLatLng.fromM(e.lngLat), "Discover")
            if (!e.originalEvent.ctrlKey)
            mapitStateDispatch(actionSetMapInteractionStateDefault());
            break;
          }
          case MapInteractionState.HentSamletFastEjendom: {
            visSamletFastEjendom(MitLatLng.fromM(e.lngLat), "Discover")
            if (!e.originalEvent.ctrlKey)
            mapitStateDispatch(actionSetMapInteractionStateDefault());
            break;
          }
          case MapInteractionState.HistoriskeKort: {
            window.open(`https://historiskekort.dk/?geometri=POINT(${e.lngLat.lng}+${e.lngLat.lat})&limit=50&offset=0&sort=titel&direction=asc`)
            break;
          }
          case MapInteractionState.Override: {
            // Info: Used when logic lives outside MapScreen, eg Drawing
            break;
          }
          case MapInteractionState.EnforceFeatureLayerPopup: {
            ZoomPointViewAble(e.lngLat.lng, e.lngLat.lat);
            PopUpForSelectedLayers(mapitStateRef.current.selectedFeatureLayerKeys, e.lngLat);
            break;
          }
          case MapInteractionState.QueryFeatures : {
            const features = mapitStateRef.current.map.queryRenderedFeatures(e.point)
            console.info("Query",features)
            break;  
          }
          case MapInteractionState.NSZoomedIn:
          case MapInteractionState.ZoomedIn:
          case MapInteractionState.Normal: {
            function clickDot(e, layerInfo) {
              if (layerInfo instanceof VectorLayer || layerInfo instanceof GeojsonWFS) {
                // vectorLayerClickFunction(e, layerInfo, (b) => mapitStateDispatch(actionAddPopup(MitLatLng.fromM(e.lngLat), (b), true)))
                return
              }
              DataLayerClickFunction(e, mapitState.mapInfo, layerInfo, (b) => mapitStateDispatch(actionAddPopup(MitLatLng.fromM(e.lngLat), (b), true)))
            }


            function clickHandler(e, layerInfo) {
              if (layerInfo.type?.includes("Point") ||
                e.features?.[0]?.geometry?.type === "Point"
              ) {
                let [lng, lat, ..._] = e.features[0].geometry.coordinates
                e.lngLat = {lng: lng, lat: lat}
              }
              clickDot(e, layerInfo)
              e.originalEvent && e.originalEvent.stopPropagation();
            }

            function LayerInfoFromSourceID(sourceId: string) {
              return Object.values(mapitStateRef.current.layers).find((layerInfo) =>
                layerInfo.handle.sourceId == sourceId
              )
            }

            // TODO: Move logic for clicking data feature here, since it is already testing features under mouse
            const activeLayers = Object.keys(mapitStateRef.current.layers).flatMap((a) => {
              return mapitStateRef.current.layers[a].handle.layerList.filter(a => {
                return a.type !== "fill-extrusion" && !a.id.includes("fill-text")
              }).map(a => a.id)
            })
            let firstAccepted = false
            const featuresUnderMouse = mapitStateRef.current.map.queryRenderedFeatures(e.point)
            const dataFeautesUnderMouse = featuresUnderMouse.filter((a) => activeLayers.includes(a.layer.id)).map((a) => {
              if (firstAccepted) {
                return a.layer.id
              }
              clickHandler({...e,features:[a]}, LayerInfoFromSourceID(a.layer.source))
              firstAccepted = true;
              return a.layer.id
            })
            const activeVectorFeatures = mapitStateRef.current.selectedFeatureLayerKeys.filter((a) => {
              return (mapitStateRef.current.featureLayers[a] instanceof VectorLayer || mapitStateRef.current.featureLayers[a] instanceof GeojsonWFS)
            }).map((a) => (mapitStateRef.current.featureLayers[a] as VectorLayer).getLayerID(0))
            const vectorFeaturesUnderMouse = featuresUnderMouse.filter((a) => activeVectorFeatures.includes(a.layer.id)).map((a) => {
              return a
            })
            if (dataFeautesUnderMouse.length) {
              return
            }
            if (vectorFeaturesUnderMouse?.length) {
              vectorLayerClickFunction(vectorFeaturesUnderMouse, (b) => mapitStateDispatch(actionAddPopup(MitLatLng.fromM(e.lngLat), (b), true)))
            }

            if (dataFeautesUnderMouse.length + vectorFeaturesUnderMouse.length === 0) 
            if (mapitStateRef.current.selectedFeatureLayerKeys.length 
              && (e.originalEvent?.altKey
              || !hasAccessToFeature(Feature.ThemeNewSec))) {
                ZoomPointViewAble(e.lngLat.lng, e.lngLat.lat)
                PopUpForSelectedLayers(mapitStateRef.current.selectedFeatureLayerKeys, e.lngLat);
            } else if (mapitStateRef.current.map.getZoom() > SettingsManager.getSystemSetting("mapInteractionStateZoomedIn")) {
              if (hasAccessToFeature(Feature.ThemeNewSec)) {
                let latLng = new MitLatLng(e.lngLat.lat, e.lngLat.lng)
                let resp = await AddressInterface.reverseGeocode(e.lngLat.lat,e.lngLat.lng);
                let addrData = await resp.json();
                let fullAddress = addrData.betegnelse;
                let tmp = fullAddress.split(',');
                let streetAndNumber = tmp[0] || fullAddress;
                let layerInfo = LayerFunc.createLayerInfoForOnePoint(latLng, streetAndNumber);
                DataLayerClickFunction({...e, features:[{geometry:{type:"Point"}}]}, mapitState.mapInfo, layerInfo, (b) => mapitStateDispatch(actionAddPopup(MitLatLng.fromM(e.lngLat), (b), true)))
                break;
              }
              if (hasAccessToFeature(Feature.PropertyInfo)) {
                windowDispatch(openWindow(WindowId.PropertyInformationDialog, {latlng:new MitLatLng(e.lngLat.lat, e.lngLat.lng)}));
              }
              break;
            }
          break;
          }
  
          default: {
              throw new Error("Unexpected MapinterationState: "+mapInteractionStateRef.current);   
          }
        }
      }
    }

    function PopUpForSelectedLayers(SelectedLayers, lngLat:{lat:number,lng:number}) {
      let activeFeatures:FeatureLayer[] = SelectedLayers.map((a) => mapitStateRef.current.featureLayers[a])
      pointInfoDotFunction(lngLat, activeFeatures, (b) => mapitStateDispatch(actionAddPopup(MitLatLng.fromL(lngLat), (b), true)))
    }
 
  let showGettingStartedInitially:boolean = 
    hasAccessToFeature(Feature.GettingStarted)
    && !Cookies.getCookieBoolean(MitCookies.HideWelcomePage);
  

  let [state, setXState] = useState<State>(initialState());

  function initialState() { return( {
    showLayerManager: (applicationState.appMode === AppMode.Embedded || smallDevice) ? false : SettingsManager.getSystemSetting("showLayerListOpenAsDefault",true),
    showLegend: (applicationState.appMode === AppMode.Embedded || smallDevice) ? false : true,
    // layers: {},
    layerPopInfo: null,
    colorRanges: [],
    showNewFeatures: false,
    featuresInRelease: false,
    showReleases: false,
    gettingStarted: false,
    callbackToLogout: props.callbackToLogout,
    showBackgroundLayer:{},
    showTrialSignupDialog: props.showTrialSignupDialog,
    callBackOnTrialSignup: props.callBackOnTrialSignup,
    showGettingStartedDialog: showGettingStartedInitially,
    showCookieAcceptDialog: !Boolean(Cookies.getCookieBoolean(MitCookies.RememberCookieAccept)) && applicationState.appMode !== AppMode.Embedded, 
    showConfirmationDialog: false,
    showStreetView: false,
    showEjerlavSearchBar: true,
    measure: undefined,
    showMeasurementOptions: false,
    mapOnClickActive: true,
    featureLayerPopupsShouldBeDisabled: false,
  });
}

  function closeIt() {
    return "Any string value here forces a dialog box to \n" + 
          "appear before closing the window.";
  }

  function OnLayerSelected(e:any, layerName:string, show:boolean, clickHandler?: (e:any) => void) {
    Logger.logAction("MapScreen","OnLayerSelected",Utils.formatString("Map Layer changed: {layer} action: {action}",{layer:layerName, action:show ? "Show":"Hide"}));
    let newBGDLState = mapitState.showBackgroundLayer;
    newBGDLState[layerName]=show;
    setState({showBackgroundLayer:newBGDLState});
    if (clickHandler) {
      if (show) {
        mapitState.map!.on("click", clickHandler);
      } else {
        mapitState.map!.off("click", clickHandler);
      }
    }
  }
  
  // -------------- button event handlers --------------
  function  toggleMeasureButton() {
    setState({ showMeasurementOptions: !state.showMeasurementOptions, mapOnClickActive: state.showMeasurementOptions });
  }

  function buttonClickedLayers(event:any) {
    // Toggle layer manager
    setState((currentState)=> {return ({showLayerManager: !currentState.showLayerManager});});
    // When showing layer manager also show legend
    setState((currentState) => {return ({showLegend: (currentState.showLayerManager ? true : currentState.showLegend)});});
    // TODO: change when search works
    // setState((currentState)=> {return ({showSearchField: false});}); 
  }

  function onCreateMarkerForLocation(location:MitLatLng, name:string) {
    let layerInfo = LayerFunc.createLayerInfoForOnePoint(location, name);
    callbackOnLayerChangesStatic("Add", layerInfo);
  }

  function onReadOnlyMapCancel() {
    setState({showSaveLinkWindow:false});
  }


  function buttonClickedInfo(event:any) {
    setState({showInfoWindow:true});
  }


  // Menu event handlers
  // -----------------------------------------------------------------------------------------------

    
  function buttonClickedShowLegend(event:any) {
    // setState({showLegend:true});
    windowDispatch(openWindow(WindowId.Legend));
  }

  function buttonClickedSearch(event:any) {
    setState((currentState)=> {return ({showSearchField: !currentState.showSearchField});});
  }

  /*
  printToFile() {
    // Experimental
    // Print to file - resulted in Error!!!
    let printPlugin = LX.setUpEasyPrint({
      hidden: true,
      sizeModes: ['A4Portrait']
    }).addTo(map); 
    printPlugin.printMap('A4Portrait', 'MyFileName');
  }
  */

  // ----------- event handlers ------------
  // -----------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------




  function callbackToHideLegend() {
    // setState({showLegend:false});
    windowDispatch(closeWindow(WindowId.Legend));
  }


    // -----------------------------------------------------------------------------------------------
    /**
   * Add a new layer
   * @param map The map object
   * @param layerInfo The layerInfo for the layer to create
   * @param zoomToFocus if true the screen is zoomed after the layer is created
   */
    function addALayer(map: MitMap, layerInfo:LayerInfo, zoomToFocus:boolean) {

      Logger.logAction("MapScreen", "AddALayer",JSON.stringify(layerInfo.styling));
      // _addALayer(map, layerInfo, zoomToFocus, 0);
      mapitStateDispatch(actionAddDataLayer(layerInfo, zoomToFocus, true));
    }

  // -----------------------------------------------------------------------------------------------
  function callBackonSettingsFeatureLayer(key: string, show: boolean) {
    setOpenFeatureLayers((a) => {
      if (a.includes(key)) {
        return a.filter((val) => val !== key)
      }
      return [...a, key];
    })
  }
 
 
  // -------------------------------------------------------

  async function visSamletFastEjendom(latLng: MitLatLng, name?: string): Promise<void> {
    try {
      let utmCoords = GenerateGeom.convertFromLatLngToUTM(latLng);
      appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving cadaster data")));
      let matrikelData = await PropertyInfoInterface.getMatrikelData(utmCoords)
        .then(result => {
          let props = result.features[0].properties;
          return {
            sfeEjdNr: props.samletfastejendomlokalid,
          };
        }).
        catch(() => {
          appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
          return undefined;
        });
      if (matrikelData && matrikelData.sfeEjdNr) {
        appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving geometry data")));
        let geoJson = await PropertyInfoInterface.getGeojsonOfSFE(matrikelData.sfeEjdNr)
          .then(result => result)
          .catch(() => {
            appMessageDispatch(actionSetErrorMessage(Localization.getText("No geometry data for this property")));
            return undefined;
          });
        geoJson.type = "FeatureCollection"
        Persistence.createLayersFromGeoJSON((matrikelData.sfeEjdNr) + " (sfe)", geoJson, new Date(), (li: LayerInfo) => {mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:{...li,propertiesToDisplay:[{name:"SFEnummer"}]}}})}, undefined, 0.25);
        appMessageDispatch(actionClearInfoMessage());
      } else {
        appMessageDispatch(actionSetErrorMessage(Localization.getText("No cadaster data for this point")));
      }
    } catch (error:any) {
      appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
    }
  }

  async function visVurderingsEjendom(latLng: MitLatLng, name?: string): Promise<void> {
    try {
      let utmCoords = GenerateGeom.convertFromLatLngToUTM(latLng);
      appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving cadaster data")));
      let matrikelData = await PropertyInfoInterface.getMatrikelData(utmCoords)
        .then(result => {
          let props = result.features[0].properties;
          return {
            sfeEjdNr: props.samletfastejendomlokalid,
          };
        })
        .catch(() => {
          appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
          return undefined;
        });
      if (matrikelData && matrikelData.sfeEjdNr) {
        appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving geometry data")));
        let geoJson = await PropertyInfoInterface.getGeojsonOfVurd(matrikelData.sfeEjdNr!)
          .then(result => result)
          .catch(() => {
            appMessageDispatch(actionSetErrorMessage(Localization.getText("No geometry data for this property")));
            return undefined;
          });
        geoJson.type = "FeatureCollection"
        Persistence.createLayersFromGeoJSON(matrikelData.sfeEjdNr + " (vurd)", geoJson, new Date(), (li: LayerInfo) => {mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:{...li}}})}, undefined, 0.25);
        appMessageDispatch(actionClearInfoMessage());
      } else {
        appMessageDispatch(actionSetErrorMessage(Localization.getText("No cadaster data for this point")));
      }
    } catch (error:any) {
      appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
    }
  }


  // ---------------- RENDERING -----------------------


/* tslint:disable */

      // When legend is deliberately hidden show a button to re-display. (Legend is also automatically hidden when no layers requireing a legend exists)
      let buttonToRedisplayLegend = windowState.find((e) => e.id == WindowId.Legend) || (applicationState.appMode === AppMode.Embedded) ? null : (
        <div className="ReOpenLegend OnMapButton" title={Localization.getText("Show Legend")} onClick={(event) => buttonClickedShowLegend(event)} style={{position:"absolute"}}>
              <MdLegendToggle />
        </div>
      );
      
      let otherLegends:{[legendId:string]: OtherLegendSpec} = {
        "CatchmentEditable": {
          renderLegend: () => {
            return (
              <div className={"Group"} key={"CatchmentEditable"}>
              <div className={"Line"}>
                <div className='TitleIcon'>
              <Catchment />
                </div>
              <div className='Title'>
              {Localization.getText("Travel time")+" (min)"}
              </div>

              </div>
              <LegendScaleForCatchment/>
              </div>
            )
          },
          showLegend: () => {
            return Boolean(windowState.find((e) => e.id == WindowId.CatchmentSelector)); 
          }
        }

      };

    
      // Add flexible background layers to legend.
      let featureLayers: FeatureLayerList =  mapitState.featureLayers;
      
      let OrderInDrawinOrder = SettingsManager.getSystemSetting("OrderBackgroundLayersInDrawingOrder")
      let selectedKeys = mapitState.selectedFeatureLayerKeys.toReversed()
      let OrderedLegendKeys = OrderInDrawinOrder ? selectedKeys : Object.keys(featureLayers);
      OrderedLegendKeys.forEach((key, idx) => {
          let lgend:OtherLegendSpec = {
            renderLegend: () => {
              if (featureLayers[key]) {
                return <BackgroundLayerCompLegend key={key} flayer={featureLayers[key]} map={mapitState.map} />;
              }
            },
            showLegend: () => {
              if (featureLayers[key] && selectedKeys.includes(key)) {
                return featureLayers?.[key]?.layer && !("noLegend" in featureLayers[key].layer && featureLayers[key].layer.noLegend); 
              }
              return false;
            }
          };
          otherLegends[key] = lgend;
      });

      function onFeatureLayerStyling(key, value) {
        
      }

      let layerArray = Object.values(mapitState.layers).filter(layer => layer.layerVariability !== LayerVariabilityType.FixedFeatureLayer);
      let customFeatureLayers = Object.values(mapitState.layers).filter(layer => layer.layerVariability === LayerVariabilityType.FixedFeatureLayer);

      // MARK: Render
      return (
          <div id="mapUI" className="App2">
          <script src="https://cdnjs.cloudflare.com/ajax/libs/core-js/2.4.1/core.min.js"></script> 
            <div id="mit-mainwindow-map">
              <div className='MouseBox' id='FillExtrusionDetail' />

              

              {createPortal(
                <>
                {buttonToRedisplayLegend}
                <Legend
                showWindow={windowState.find((e) => e.id == WindowId.Legend) ? 1 : 0} 
                layers={layerArray}
                layerHiearchy={mapitState.layerHierarchy}
                callBackOnStyleChange={(layerInfo: LayerInfo) => {
                  mapitStateDispatch(actionRemoveDataLayer(layerInfo.layerId, true));
                  mapitStateDispatch(actionAddDataLayer(layerInfo, false, true));
                  return layerInfo.layerId
                }}
                callBackToHideWindow={() => callbackToHideLegend()}
                otherLegends={otherLegends}
                /> 
                </>
              , document.getElementById("Mit-MapOverlay") || document.body)}
              
              <ReadOnlyMapDialog
                showWindow={Boolean(state.showSaveLinkWindow)}
                url={state.saveLinkUrl || ""}
                embedCode={state.saveLinkEmbedCode || ""}
                expireDate={state.saveLinkExpireDate || new Date()}
                callbackOnCancel={()=> {onReadOnlyMapCancel();}}
              />

              <div ref={mapContainer} id="map" className='MapScreen-Container' />

              <div 
                className="mit-mapscreen-icon" 
                id="mit-layers-icon" 
                style={{"display":hasAccessToFeature(Feature.ShowLayerControls)? '':'none'}} 
                onClick={(event) => buttonClickedLayers(event)}
              />
              <div className="hide-element int-bot mit-icon" id="mit-info-icon" onClick={(event) => buttonClickedInfo(event)}/>
              <CookieApproval/>
              {/* <QuickOrthoPhoto /> */}
              <div className="dropContainer" id="dropContainer"/>
              </div>
              <CustomerLogo
                customerIconURL={mapitState.mapInfo.customerIconURL!}
                customerIconLink={mapitState.mapInfo.customerIconLink!}
              />
              <PopupHandler popups={mapitState.mapPopout} />
              
        </div>
      );
    }


function PopupHandler(props: {popups:MitPopup[]}) {
  return (
    <>
    {props.popups.map((a) => {
      return createPortal(a.content, a.container, a.key)
    })}
    </>
  )
}


export default MapScreen;
// MARK: Datalayer Popups
export function DataLayerClickFunction(e: any, mapInfo:MapInfo, layerInfo:LayerInfo ,callBackJSX:(a:JSX.Element) => void) {
  let coordinates = e && e.lngLat;
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }
  
  const type = e.features[0].geometry.type;
  switch (GeoJsonTypeToPopupType(type)) {
    case 'Point': {
      
      callBackJSX(<PointPopup layerInfo={layerInfo} feature={e.features[0]} lat={coordinates.lat} lng={coordinates.lng} />)
      break;
    }
    case 'Line': {
      callBackJSX(<LinePopup layerInfo={layerInfo} feature={e.features[0]} />)
      break;
    }
    case 'Area': {
      callBackJSX(<AreaPopup layerInfo={layerInfo} feature={e.features[0]} />)
      break;
    }
  }
}

function vectorLayerClickFunction(layers:any[], callBackJSX:(a:JSX.Element) => void) {
  callBackJSX(<VectorLayerPopup vectorFeatures={layers} />)
}

function pointInfoDotFunction(lngLat:{lng:number,lat:number}, flayer:FeatureLayer[], callBackJSX:(a:JSX.Element) => void) {
  callBackJSX(<PointInfoPopup lngLat={lngLat} layers={flayer} />)
}

function BackgroundLayerCompLegend(props:{flayer:FeatureLayer, map:any}) {
  const [zoom, setZoom] = useState(0);

  const ftl = props.flayer.layer;

  // Manage multiple layers
  const url = props.flayer.getLegendURL?.() || "";
  const transparentUrl = url + "&transparent=true"
  const legendCrop = ftl.legendCrop
  const legendOffset = ftl.legendOffset
  const legendHueRotate =  ftl.paint?.["raster-hue-rotate"];
  const legendSaturate = (ftl.paint?.["raster-saturation"] || 0)+1

  useEffect(() => {
    if (props.map) {
      function zoomEnd(e) {
        setZoom(e.target.getZoom())
      }
      props.map.on("zoomend", zoomEnd)
      return () => {
        props.map.off("zoomend", zoomEnd)
      }
    }
  },[props.map])

  if (zoom && ftl.minZoom > zoom) {
    return (
    <div className="Group" key={ftl.label}>
    <div className="Line">
    <div className="TitleIcon"><BsLayers /></div>
    <div className='Title' >
    {ftl.translationTable ? Localization.getTextSpecificTable(ftl.label, ftl.translationTable) : ftl.label}
    </div>
    </div>
    <div className='Group'>
    <BsExclamationTriangleFill style={{verticalAlign: "sub", fontSize:"14px", marginRight:"0.5em"}} color='#cca164' />{Localization.getText("Zoom in to show")}
    </div>
    </div>
    )
  }
  if ((props.flayer instanceof VectorLayer || props.flayer instanceof GeojsonWFS) && url) {
    const layer = props.flayer;
    return (
      <div className="Group" key={ftl.label}>
      <div className="Line">
      <div className="TitleIcon"><BsLayers /></div>
      <div className='Title' >
      {ftl.translationTable ? Localization.getTextSpecificTable(ftl.label, ftl.translationTable) : ftl.label}
      </div>
      </div>
      <div className="Group">
        <div key={layer.getLegendURL()} className="LegendImg" style={{maxHeight:(legendCrop ? legendCrop + "px": "none")}} >
        <Image 
            style={{objectFit:"none", marginTop: (legendOffset || 0) + "px", filter:` hue-rotate(${legendHueRotate || 0}deg) saturate(${legendSaturate})` }}
            className="mit-legend-ows" 
            src={layer.getLegendURL() + "&transparent=true"}
            fluid={true}
        />
        </div> 
      </div>
      </div>)
  }
  if (!("noLegend" in ftl && ftl.noLegend === true) && url ) {
    return (
    <div className="Group" key={ftl.label}>
    <div className="Line">
    <div className="TitleIcon"><BsLayers /></div>
    <div className="Title" >
    {ftl.translationTable ? Localization.getTextSpecificTable(ftl.label, ftl.translationTable) : ftl.label}
    </div>
    </div>
    <div className="Group" >
            {ftl.layers.split(",").map((a) => url.replace(ftl.layers, a)).filter((a,b) => !ftl.legendExclude?.includes(b)).map((imgUrl, idx) => 
              <div key={imgUrl} className="LegendImg" style={{maxHeight:(legendCrop ? legendCrop + "px": "none")}} >
              <Image 
                  style={{objectFit:"none", marginTop: (legendOffset || 0) + "px", filter:` hue-rotate(${legendHueRotate || 0}deg) saturate(${legendSaturate})` }}
                  className="mit-legend-ows" 
                  src={imgUrl!  + "&transparent=true"}
                  fluid={true}
              />
              <div className="LegendImgText">
              {ftl.legendLabels?.[Localization.getLanguage()][idx]}
              </div>
              </div> 
            )}
    </div>
    </div>)
  } 
    return null

}

const PointsInGeoJson = {
  "Point":"Point",
  "MultiPoint":"Point",
  "MultiLineString":"Line",
  "LineString":"Line",
} as const
function GeoJsonTypeToPopupType(type: string):"Point"|"Line"|"Area" {
  return PointsInGeoJson[type] ?? "Area"
}
