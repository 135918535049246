import chroma from "chroma-js"

// ToDo: Where to configure icons to use (by design style/template)
import IconDashboard from '../svg/NewSecSVG/Dashboard.svg'
import IconDiscover from '../svg/NewSecSVG/Discover.svg'
import Explore from '../svg/NewSecSVG/Explore.svg'
import IconProjects from '../svg/NewSecSVG/Projects.svg'
import Ejerskab from '../svg/NewSecSVG/Ejerskab.svg'
import Layers from '../svg/NewSecSVG/Layers.svg'
import Kort from '../svg/NewSecSVG/Kort.svg'
import Poi from '../svg/NewSecSVG/Poi.svg'
import ShareMap from '../svg/NewSecSVG/Del_kort.svg'
import Download from '../svg/NewSecSVG/Download.svg'
import GetLink  from '../svg/NewSecSVG/SendLink.svg'
import IconDemography from '../svg/NewSecSVG/Demografi.svg'
import IconTravelTime from '../svg/NewSecSVG/Rejsetid.svg'
import { Localization, Cookies, MitCookies, actionSetInfoMessage, AppMessagesContext, SessionContext, SessionActionType, actionSetLoginDialogMode, LoginDialogMode, SettingsManager, UserHelpContext, showReleases, showUserGuide, LicenseStateCompact, LanguageSelector, LicensingContext, actionSetProgressMessage, actionSetErrorMessage, actionLogoff, actionDisableLicense, actionClearProgressMessage, actionSwapLicenseVariant } from '@viamap/viamap2-common';
import { RightPaneContext, RightPaneModus, actionGoToModus, actionToggleBurgerMenu, actionToggleModus } from 'src/states/RightPaneState';
import { ProtectedFeature } from './ProtectedFeature';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';
import Dashboard from './Dashboard';
import { Projects } from './Projects';
// import { CompleteMultipleCatchmentDialog } from './MultipleCatchmentDialog';
import { MapInteractionState, MapitStateContext, actionAddDataLayer, actionSetMapInteractionState, actionSetMapInteractionStateDefault, actionSetPoiTypesToShow } from 'src/states/MapitState';
import React, { Fragment, useContext, useEffect, useId, useState } from 'react';
import { BsFillPostcardFill, BsFillCameraFill, BsFillCarFrontFill, BsFillSignpost2Fill, BsQuestion, BsCloudUpload, BsSave, BsGear, BsMap, BsGearWide, BsLock, BsXSquare, BsInfo, BsLink45Deg, BsLayers, BsSearch, BsImage, BsPersonWalking, BsPeople, BsRocket, BsRocketTakeoff, BsPrinter, BsDiagram3, BsPen } from 'react-icons/bs'; 
import { BiCross } from 'react-icons/bi'
import { GiPentarrowsTornado} from 'react-icons/gi'
import { PoiSelector } from './PoiSelector';
import { DrawingTools } from './DrawingToolsV2';
import { MdDraw, MdMenu, MdQueryStats } from 'react-icons/md';
import { FiPrinter  } from 'react-icons/fi';
import { PiMapPinFill } from 'react-icons/pi';
import { LayerFunc } from 'src/managers/LayerFunc';
import { DynamicDataDrivenLayerPOI } from 'src/managers/DynamicDataDrivenLayer';
import { POIS } from 'src/HLED/managers/PoiManager';
import { LayerInfo, MarkerType } from 'src/common/managers/Types';
import { createPortal } from 'react-dom';
import { DeviceScreenSize } from 'src/states/ApplicationStateFeatureMapping';
import { LayerContainer } from './LayerContainer';
import { ExtendedMenuButton, MenuButton, VLine } from './MitGlassComponents';
import { AiFillBug, AiOutlineUserSwitch } from 'react-icons/ai';
import { MapitButtonsAndOverlays } from './MapitButtonsAndOverlays';
import { LuCircleDot } from 'react-icons/lu';
import { actionGenerateCatchmentSimple, actionSetCatchmentState, CalculationMode, CatchmentState, CatchmentStateContext } from 'src/states/CatchmentState';
import { CatchmentSelector } from './CatchmentSelector';
import { CatchmentInterface } from 'src/managers/CatchmentInterface';
import { ExportData } from 'src/managers/ExportData';
import { licenseVariantToRoles } from 'src/states/ApplicationStateVariantMapping';
import { NavigationBarMicro } from "./NavigationBarMicro"
import { TSwitch } from "./TSwitch"
import Catchment from "../svg/catchment/catchment.svg?react"
import SelectPoints from "../svg/catchment/selectPoints.svg?react"
import { actionSimulateExpiredLicense, actionSimulateInvalidLicense } from "@viamap/viamap2-common/dist/states/ViamapLicensingState"
import { MitStorage } from "src/managers/MitStorage"
import { BackwardItem, Convert, GlobalSearch, HambergerMenu, Layer, MouseCircle, Note, NoteText, PenTool, People, Printer, RecoveryConvert, Routing, SearchZoomIn, Star } from "iconsax-react"
import { BNBOContext } from "src/BNBOModule/BNBOState"
import { message } from "./Message"
import { NonBlockingError } from "./NonBlockingError"
import { AdvancedDragModalMiniContainer } from "src/componentsUtils/AdvancedDragModalMiniContainer"
import { closeWindow, openWindow, WindowId, WindowState } from "src/WindowManager/WindowState"
import { useWindowContext } from "src/WindowManager/useWindowContext"


export default function RightPane(props: {children:React.ReactNode}) {
  const {state: rightPaneState, isActive, dispatch: rightPaneDispatch} = useContext(RightPaneContext)
  const {state: applicationState,hasAccessToFeature} = useContext(ApplicationStateContext)
  const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
  const [showBlockBar, setShowBlockBar] = useState(true);

  return (
    <>
    {props.children}
    <div id='Mit-ApplicationOverlay' >
      <SideBarBlockAll show={showBlockBar} >
      <NavigationBarMicro onCollapse={() => setShowBlockBar((a) => !a)} />
      
      <BurgerMenu show={rightPaneState.showBurgerMenu} />
      <SideBarNonBlocking show={true} >
        <></>
      </SideBarNonBlocking>
      <MapitButtonsAndOverlays/>

      </SideBarBlockAll>
      
    </div>
    </>
  )
}

function SideBarBlockAll(props:{show: boolean, children}) {
  return (<>
  {props.show ? <BlockingElements /> : null}
  <div id='Mit-NonBlockingOverlay'>
    <NonBlockingError />
    <div className="MapCorners c1" ></div>
    <div className="MapCorners c2" ></div>
    <div className="MapCorners c3" ></div>
    <div className="MapCorners c4" ></div>
    <div className="MapCorners c5" ></div>
    {props.children}
    </div>
  </>)
}

function SideBarNonBlocking(props:{show:boolean, children}) {
  if (!props.show) {
    return <div id='Mit-MapOverlay'>
      <AdvancedDragModalMiniContainer />
    {props.children}
    </div>
  }
  return (<>
  <NonBlockingElements />
  <div id='Mit-MapOverlay'>
    <AdvancedDragModalMiniContainer />
    {props.children}
    </div>
  </>)
}

function BlockingElements() {
  const {isActive} = useContext(RightPaneContext)

  return (
    <>
    <SubMenu />
    <SubSubMenuMap show={isActive(RightPaneModus.Map)} />
    </>
  )
}

function NonBlockingElements() {
  const {isActive} = useContext(RightPaneContext);
  const {state: applicationState,hasAccessToFeature} = useContext(ApplicationStateContext);
  const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
  const { state: windowState} = useWindowContext();
  

  return (
    <>
      <DrawingTools show={isActive(RightPaneModus.Map_Measure)} />
      <Dashboard show={isActive(RightPaneModus.Dashboard)} />
      <RenderOwnerShipDiagram show={isActive(RightPaneModus.Ejerskab)} />
      <RenderGenerateReport show={isActive(RightPaneModus.GeoDataRapport)} />
      <RenderSearchWindow show={isActive(RightPaneModus.Search)} />
      <ShareMapBar show={isActive(RightPaneModus.Map_Share)} />
      <Projects show={isActive(RightPaneModus.Projects)} />
      <POI show={isActive(RightPaneModus.Map_POI)} />
      <Discover show={isActive(RightPaneModus.Map_Discover)} />
      <RenderOwnerShipDiagram show={isActive(RightPaneModus.Map_Ownership)} />
      
      <LayerContainer show={
        isActive(RightPaneModus.Map_Layer) && (!(applicationState.deviceScreenSize == DeviceScreenSize.Phone) || (!windowState.find((e) => e.id === WindowId.ConfirmLayerList)))} />
      
        <CatchmentSideBar />
    </>
  )
}

const RenderPointDistanceTableWindow = (props:{show:boolean}) => {
  const {dispatch: windowDispatch} = useWindowContext();

  useEffect(() => {
    if (props.show) {
      windowDispatch(openWindow(WindowId.PointDistanceTable, {dialogMode:"Polygons"}))
    } else {
      windowDispatch(closeWindow(WindowId.PointDistanceTable))
    }
  },[props.show])

  return (null);
}

const RenderGenerateReport = (props:{show:boolean}) => {
  const {dispatch: windowDispatch} = useWindowContext();

  useEffect(() => {
    if (props.show) {
      windowDispatch(openWindow( WindowId.DemographyReportDialog));
    } else {
      windowDispatch(closeWindow( WindowId.DemographyReportDialog));
    }
  },[props.show])

  return (null);
}

const RenderSearchWindow = (props: {show: boolean}) => {
  const {state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext);
  const {hasAccessToFeature} = useContext(ApplicationStateContext)
  const { state: rightPaneState, dispatch: rightPaneDispatch } = useContext(RightPaneContext);
  const {state: windowState, dispatch: windowDispatch } = useWindowContext(); 
  
  useEffect(() => {
    if (props.show) {
      windowDispatch(openWindow(WindowId.Explore));
    } else {
      windowDispatch(closeWindow(WindowId.Explore));
    }
  },[props.show])

  useEffect(() => {
    if (windowState.find((a) => a.id === WindowId.Explore)) {
        rightPaneDispatch(actionGoToModus(RightPaneModus.Map_Explorer))
    }
  },[windowState])

  return (null);
}

const RenderOwnerShipDiagram = (props:{show:boolean}) => {
  const {state: mapitState} = useContext(MapitStateContext);
  const {hasAccessToFeature} = useContext(ApplicationStateContext)
  const { state: rightPaneState, dispatch: rightPaneDispatch } = useContext(RightPaneContext);
  const {state: windowState, dispatch: windowDispatch } = useWindowContext(); 

  useEffect(() => {
    if (props.show) {
      windowDispatch(openWindow(WindowId.OwnerShipDiagram));
    } else {
      windowDispatch(closeWindow(WindowId.OwnerShipDiagram));
    }
  },[props.show])

  useEffect(() => {
    if (windowState.find((a) => a.id === WindowId.OwnerShipDiagram)) {
      rightPaneDispatch(actionGoToModus(RightPaneModus.Map_Ownership))
    }
  },[windowState])

  return (null);
}

const FeatureList = {
  [Feature.BasicMap]:RightPaneModus.Map,
}

function SubMenu() {
  const {state: rightPaneState, dispatch: rightPaneDispatch} = useContext(RightPaneContext)
  const {state: applicationState,hasAccessToFeature} = useContext(ApplicationStateContext)
  
  let activeFeatures = Object.keys(FeatureList).filter((a) => hasAccessToFeature(a))
  useEffect(() => {
    if (activeFeatures.length === 1) {
      rightPaneDispatch(actionGoToModus(FeatureList[activeFeatures[0]]))
    }
    if (activeFeatures.length < 1) {
      rightPaneDispatch(actionGoToModus(RightPaneModus.Map))
    }
  },[applicationState])
  
  function isActive(a:RightPaneModus) {
    let splited = rightPaneState.current.split("_")
    if (splited.map((a,b,c) => c.slice(0,(b+1)).join("_")).includes(a)) {
      return true
    }
    return false
  }

  if (applicationState.deviceScreenSize === DeviceScreenSize.Phone) {
    return <></>
  }

  if (activeFeatures.length < 2) {
    return <></>
  }

  return (
      <></>
  )
}

function ShareMapBar(props:{show:boolean}) {
  const {dispatch: windowDispatch} = useWindowContext();

  if (!props.show) 
  return null;

  return (
    <div className="ExtendedSideBar" >
      <div className='LeftInnerShadow' ></div>
      <div className='SidePanelScrolling'>
        <ProtectedFeature feature={Feature.SaveMap} contentsIfNoAccess={<></>}>
        <ExtendedMenuButton className={'invers'} style={{marginTop: "15px"}} onClick={(e) => windowDispatch(openWindow(WindowId.SaveMapDialog))} toolTip={Localization.getText("Download")} ><img style={{ height:"100%", background:"#f4f4f4", cursor:"pointer" ,borderRadius:"5pt"}} src={Download} /></ExtendedMenuButton>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SaveLink} contentsIfNoAccess={<></>}>
        <ExtendedMenuButton className='ExtendedMenuButton invers' style={{marginTop: "15px"}} onClick={(e) => {
          Cookies.getCookieBoolean(MitCookies.RememberMapLinkGDPRAccept) ? windowDispatch(openWindow(WindowId.SaveLinkDialog)) : windowDispatch(openWindow(WindowId.GDPRNoticeApproval, {
            cookie: MitCookies.RememberMapLinkGDPRAccept,
            acceptCallback: () => windowDispatch(openWindow(WindowId.SaveLinkDialog))
          }));
          }} toolTip={Localization.getText("Get Link")} ><img style={{ height:"100%", background:"#f4f4f4", cursor:"pointer" ,borderRadius:"5pt"}} src={GetLink} /></ExtendedMenuButton>
      </ProtectedFeature>
      </div>
    </div>
  )
}

function SubSubMenuMap(props:{show:boolean}) {
  const {state: rightPaneState, dispatch: rightPaneDispatch} = useContext(RightPaneContext);
  const {state: mapitState, dispatch: mapitDispatch} = useContext(MapitStateContext)
  const {state: windowState, dispatch: windowDispatch} = useWindowContext();

  if (!props.show) {return null}
  
  function isActive(a:RightPaneModus) {
    let splited = rightPaneState.current.split("_")
    if (splited.map((a,b,c) => c.slice(0,(b+1)).join("_")).includes(a)) {
      return true
    }
    return false
  }

  function isWindowActive(a:WindowId) {
    if (windowState.find((e) => e.id === a)) {
      return true
    }
    return false
  }

  function toogleWindow(a:WindowId, options?: {}) {
    if (isWindowActive(a)) {
      windowDispatch(closeWindow(a))
      return
    }
    windowDispatch(openWindow(a, options))
  }

  function isNewWindowActive(wId:WindowId) {
    return !!windowState.find((a) => a.id === wId)
  }
  function toogleNewWindow(wId:WindowId, options?: {}) {
    if (isNewWindowActive(wId)) {
      windowDispatch(closeWindow(wId))
      return
    }
    windowDispatch(openWindow(wId, options))
  }



  return (
    <div className="SidePanel" >
      <div className='MainIcon'>
      
      <TSwitch 
        NS={<img src='Estate Explorer favikon hvid.svg' style={{width:"100%"}} />} 
        DF={<img src='favicon.png' style={{width:"100%"}} />}
       />

      </div>
    <VLine color={"white"} />
    <div className='SidePanelScrolling' >
      <ProtectedFeature feature={Feature.BasicMap} contentsIfNoAccess={<></>}>
        <MenuButton onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Layer))} className={"MenuButton " + (isActive(RightPaneModus.Map_Layer) ? "active" : "")} toolTip={Localization.getText("Layers")}
          active={isActive(RightPaneModus.Map_Layer)}
          activeArrow={true}
          >
          <TSwitch NS={<NSIcons src={"NS/MenuIcons/Kortlag_layers.svg"} fill={isActive(RightPaneModus.Map_Layer)} />} 
          DF={<Layer variant={isActive(RightPaneModus.Map_Layer) ? "Bold" : undefined} />} />
        </MenuButton>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.ExplorerDiscover} contentsIfNoAccess={<></>}>
        <MenuButton onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Discover))} className={"MenuButton " + (isActive(RightPaneModus.Map_Discover) ? "active" : "")} toolTip={Localization.getText("Discover")}
          active={isActive(RightPaneModus.Map_Discover)}
          activeArrow={true}
          >
          <TSwitch NS={<NSIcons src={"NS/MenuIcons/Undersoeg_binoculars.svg"} fill={isActive(RightPaneModus.Map_Discover)} />} DF={<SearchZoomIn variant={isActive(RightPaneModus.Map_Discover) ? "Bold" : undefined} />} />
        </MenuButton>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.MeasurementTool} contentsIfNoAccess={<></>} >
      <MenuButton onClick={() => {
        if (!windowState.find((a) => a.id === WindowId.BNBOMarkAreaEditor || a.id === WindowId.BNBOAreaEditor)) {
          rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Measure))
        } else {
          message.info("Can't open drawModus, while Erstatnings Editor is active")
        }
      }} className={"MenuButton " + (isActive(RightPaneModus.Map_Measure) ? "active":"")} toolTip={Localization.getText("Draw")}
        active={isActive(RightPaneModus.Map_Measure)}
        activeArrow={true}
        >
        <TSwitch NS={<NSIcons src="NS/MenuIcons/Tegn_pencil.svg" fill={isActive(RightPaneModus.Map_Measure)} /> } DF={<PenTool variant={isActive(RightPaneModus.Map_Measure) ? "Bold" : undefined} />} />  
      </MenuButton> {/* <MdTraffic /> */}
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.POI} contentsIfNoAccess={<></>} >
        <MenuButton onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_POI))} className={"MenuButton " + (isActive(RightPaneModus.Map_POI) ? "active" : "")} toolTip={"POI"}
            active={isActive(RightPaneModus.Map_POI)}
            activeArrow={true}
          >
          <TSwitch NS={<NSIcons src="NS/MenuIcons/POI_bookmark-star.svg" fill={isActive(RightPaneModus.Map_POI)} />} DF={<Star variant={isActive(RightPaneModus.Map_POI) ? "Bold" : undefined} />} />
        </MenuButton>
      </ProtectedFeature>
      <VLine color={"white"} />
      {/* <ProtectedFeature feature={Feature.TravelTime} contentsIfNoAccess={<></>}>
        <MenuButton onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Catchment))} className={"MenuButton " + isActive(RightPaneModus.Map_Catchment) ? "active" : ""} toolTip={Localization.getText("Travel time")}><img src={Catchment} /></MenuButton>
      </ProtectedFeature> */}
      <ProtectedFeature feature={Feature.TravelTimeMultiple} contentsIfNoAccess={<></>}>
        <MenuButton onClick={() => toogleWindow(WindowId.PointDistanceTable, {dialogMode:"Polygons"})} className={"MenuButton " + (isWindowActive(WindowId.PointDistanceTable) ? "active" : "")} toolTip={Localization.getText("abbreviation:Combined catchment area")}>
          <TSwitch NS={<NSIcons src={"NS/MenuIcons/rejsetid_stopwatch.svg"} fill={isWindowActive(WindowId.PointDistanceTable)} />} DF={
            <Routing variant={isWindowActive(WindowId.PointDistanceTable) ? "Bold" : undefined} />
          } />
        </MenuButton>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.BNBOTool} contentsIfNoAccess={<></>} >
        <MenuButton onClick={() => toogleNewWindow(WindowId.BNBOSFEStatus)} className={"MenuButton " + (isNewWindowActive(WindowId.BNBOSFEStatus) ? "active" : "")} toolTip={"BNBO"}>
        <RecoveryConvert
          variant={isNewWindowActive(WindowId.BNBOSFEStatus) ? "Bold" : undefined} />
        </MenuButton>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.GeodataReport} contentsIfNoAccess={<></>} >
        <MenuButton onClick={() => toogleWindow(WindowId.DemographyReportDialog)} className={"MenuButton "+ (isWindowActive(WindowId.DemographyReportDialog) ? "active" : "")} toolTip={Localization.getText("Demography")}>
          <TSwitch NS={<NSIcons src={"NS/MenuIcons/Demografi_people.svg"} fill={isWindowActive(WindowId.DemographyReportDialog)} />} DF={<People variant={isWindowActive(WindowId.DemographyReportDialog) ? "Bold" : undefined} />} />
        </MenuButton>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SpatialExport} contentsIfNoAccess={<></>}>
        <MenuButton onClick={() => toogleWindow(WindowId.SpatialSelection)} className={"MenuButton " + (isWindowActive(WindowId.SpatialSelection) ? "active" : "")} toolTip={"Udvælg"}>
          <TSwitch
            NS={<NSIcons src={"NS/MenuIcons/Udvaelg punkt_pin-map.svg"} fill={isWindowActive(WindowId.SpatialSelection)} />}
            DF={<MouseCircle variant={isWindowActive(WindowId.SpatialSelection) ? "Bold" : undefined} />}
            />
        </MenuButton>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.ExplorerExplore} contentsIfNoAccess={<></>}>
        <MenuButton onClick={() => toogleNewWindow(WindowId.Explore)} className={"MenuButton " + (isNewWindowActive(WindowId.Explore) ? "active" : "")} toolTip={Localization.getText("Explore")}>
          <TSwitch NS={<NSIcons src="NS/MenuIcons/Explore_rocket-takeoff.svg" fill={isNewWindowActive(WindowId.Explore)} />} DF={<GlobalSearch variant={isNewWindowActive(WindowId.Explore) ? "Bold" : undefined} />} />
        </MenuButton>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.ExplorerOwnership} contentsIfNoAccess={<></>}>
        <MenuButton onClick={() => toogleNewWindow(WindowId.OwnerShipDiagram)} className={"MenuButton " + (isNewWindowActive(WindowId.OwnerShipDiagram) ? "active" : "")} toolTip={Localization.getText("Ownership")}>
          <TSwitch NS={<NSIcons src="NS/MenuIcons/Ejerskab_diagram-3.svg" fill={isNewWindowActive(WindowId.OwnerShipDiagram)} />} DF={<BackwardItem variant={isNewWindowActive(WindowId.OwnerShipDiagram) ? "Bold" : undefined} />} />
        </MenuButton>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.MapPlot} contentsIfNoAccess={<></>}>
        <MenuButton onClick={() => toogleNewWindow(WindowId.PlotScreen)} className={"MenuButton " + (isNewWindowActive(WindowId.PlotScreen) ? "active" : "")} toolTip={Localization.getText("Plot")} >
          <Printer variant={isNewWindowActive(WindowId.PlotScreen) ? "Bold" : undefined} />
        </MenuButton>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.Datarapport} contentsIfNoAccess={<></>}>
        <MenuButton onClick={() => toogleWindow(WindowId.EjendomskortDialog)} className={"MenuButton " + (isWindowActive(WindowId.EjendomskortDialog) ? "active" : "")} toolTip={Localization.getText("Data report")} >
          <NoteText variant={isWindowActive(WindowId.EjendomskortDialog) ? "Bold" : undefined} />    
        </MenuButton>
      </ProtectedFeature>
      </div>
      <VLine color={"white"} />
      <div className='SettingsMore'>
        <div className='MenuButton' onPointerDown={(e) => e.stopPropagation()} onClick={() => rightPaneDispatch(actionToggleBurgerMenu())}>
          <HambergerMenu />
        </div>
      </div>
      </div>
      )
}

function NSIcons(props:{src:string, fill?:boolean}) {
  return (
    <img src={props.src.replace(".", (props.fill?"-fill.":"."))} style={{width:"100%"}} />
  )
}




function Discover(props:{show:boolean}) {
  let {state, dispatch} = useContext(MapitStateContext)

  useEffect(() => {
    return () => dispatch(actionSetMapInteractionStateDefault())
  },[props.show])

  if (!props.show) {
    return null
  }

  function handleClick(x: MapInteractionState) {
    if (x !== state.mapInteractionState) {
      dispatch(actionSetMapInteractionState(x)) 
    } else {
      dispatch(actionSetMapInteractionStateDefault())
    }
  }

  let discPrefix = "NS/DiscoverIcons/"
  const Buttons = [
    {text:"Add Pin",           icon: <PiMapPinFill />,       action: MapInteractionState.ClickToSetMarker,              feature: undefined},
    {text:"Info",              icon: <BsFillPostcardFill />, action: MapInteractionState.ClickToShowPropertyInfo,       feature: Feature.PropertyInfo},
    {text:"Oblique Photo",     icon: <BsFillCameraFill />  , action: MapInteractionState.ClickToShowObliquePhotos,      feature: Feature.ObliquePhoto},
    {text:"Street Viewer",     icon: <BsFillSignpost2Fill />,action: MapInteractionState.ClickToShowStreetView,         feature: Feature.StreetView},
    {text:"Travel Time",       nsIcon: <img src={discPrefix+"rejsetid_stopwatch-fill_45465B.svg"} /> , icon: <BsFillCarFrontFill />, action: MapInteractionState.ClickToShowTravelTimePolygons, feature: Feature.TravelTime},
    {text:"Real estate",       nsIcon: <img src={discPrefix+"vurderingsejendom_cash-coin_45465B.svg"} /> , icon: "V",         action: MapInteractionState.HentVurderingsEjendom,         feature: Feature.VisVurderingsejendom},
    {text:"Total real estate", nsIcon: <img src={discPrefix+"samlet fast ejendom_layout-wtf_45465B.svg"} /> , icon: "S",         action: MapInteractionState.HentSamletFastEjendom,         feature: Feature.VisSamletFastEjendom},
    {text:"Historiskekort",    icon: "H",           action: MapInteractionState.HistoriskeKort,      feature: Feature.LinkHistoriskeKort},
    {text:"Dot Info",          nsIcon: <img src={discPrefix+"punktinfo_info-circle-fill.svg"} /> , icon: <BiCross />   ,         action: MapInteractionState.EnforceFeatureLayerPopup,      feature: undefined},
    {text:"Point Distance",    icon: <GiPentarrowsTornado />,action: MapInteractionState.ClickToShowPointDistanceTable, feature: Feature.TravelTimeFromPoint},
    {text:"QueryFeatures",     icon: <MdQueryStats />,       action: MapInteractionState.QueryFeatures,                 feature: Feature.Debugging},
    // TODO: Add https://maplibre.org/maplibre-gl-js-docs/example/queryrenderedfeatures/
  ]

  return (
    <div className='ExtendedSideBar' >
      <div className='LeftInnerShadow' ></div>
      <div className='SidePanelScrolling'>
      {Buttons.map((a) => {
        if (a.feature == undefined) {
          return (
            <Fragment key={a.text}>
            <ExtendedMenuButton className={(a.action === state.mapInteractionState ? " active" : "")}  onClick={(e) => handleClick(a.action)} toolTip={Localization.getText(a.text)}>
              <TSwitch DF={a.icon} NS={a.nsIcon} />
            </ExtendedMenuButton>
            </Fragment>
          )
        }
        return (
        <ProtectedFeature key={a.text} feature={a.feature} contentsIfNoAccess={<></>} >
            <ExtendedMenuButton className={(a.action === state.mapInteractionState ? " active" : "")}  onClick={(e) => handleClick(a.action)} toolTip={Localization.getText(a.text)}>
              <TSwitch DF={a.icon} NS={a.nsIcon} />
            </ExtendedMenuButton>
        </ProtectedFeature>
      )})}
      </div>
    </div>
  )
}



function POI(props:{show:boolean}) {
  const { state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext);
  const {dispatch: appMessageDispatch} = useContext(AppMessagesContext);
  const {state: rightPaneState, dispatch: rightPaneDispatch} = useContext(RightPaneContext)
  
  if (!!!props.show) {
    return <></>
  }

  // function mapitStateDispatch(arg0: any) {
  //   throw new Error('Function not implemented.');
  // }

  return (<div className='ExtendedSideBar'>
    <div className='LeftInnerShadow' ></div>
        <PoiSelector 
                onSavePoisAsLayer={async (poiTypes:string[]) => {
                  const createAsSingleLayer:boolean = true;
                  if (createAsSingleLayer) {
                    let bounds:any = mapitState.map.getBounds();
                    let bboxMap:any = bounds.toArray();
                    let bbox=[...bboxMap[0], ...bboxMap[1]];
                    let featureCollection = await (new DynamicDataDrivenLayerPOI({label:"pois",layers:JSON.stringify(poiTypes)})).getDataForBBOX(bbox);
                    let title = poiTypes.reduce((result, poi, idx) => {
                      let label=POIS[poi][Localization.getLanguage()];
                      return result + (idx > 0 ? ", ":"")+ label;
                    }, "")
                    let newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureCollection(featureCollection, title);
                    newLayerInfo.styling = {...newLayerInfo.styling, "markerType": MarkerType.Icon, "iconByProperty": "icon"};
                    mapitStateDispatch(actionAddDataLayer(newLayerInfo));
                    // Hide the POI's that was currenly shown - and now shown as a data layer.
                    mapitStateDispatch(actionSetPoiTypesToShow([]));
                    // Show layer list such that new layer can be seen
                    rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Layer))
                    appMessageDispatch(actionSetInfoMessage(Localization.getFormattedText("Pois has been copied to a new layer '{title}'", {title})));
                  }
                }}
          />
    </div>
  )
}

function GrabButton(props: {showLayerList, onClick, observeClass, text?}) {
  const [UpdateState, sUS] = useState(0)

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      sUS((a) => a + 1)
      setTimeout(() => sUS((a) => a + 1), 200)
    })
    observer.observe(document.getElementsByClassName("maplibregl-control-container")?.[0] ?? document.body)
    return () => {
      observer.disconnect()
    }
  })

  return (
    createPortal(
    <button className='GrabButton' style={{position: "absolute", right: "var(--spacingRightBar, 0px)" , top: "20%", zIndex: 1}} onClick={(() => props.onClick((a) => !a))}>
      <div className='GrabButton-text' >{Localization.getText("SideBar")}</div>
      <div>||</div>
    </button>
    , document.getElementById("Mit-ApplicationOverlay") || document.body)
  )
}


function BurgerBtn(props: {
  icon: React.ReactElement,
  text: string,
  onClick: () => void,
  onDoubleClick?: () => void,
}) {
  return (
    <div className='BurgerBtn' onDoubleClick={props.onDoubleClick} onClick={props.onClick}>
      <div className='icon' >{props.icon}</div>
      <div className='text' >{props.text}</div>
    </div>
  )
}

function BurgerMenu(props: {show:boolean}) {
  const {state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext)
  const {state: bnboState} = useContext(BNBOContext)
  const burgerMenuId = useId()
  const {state: licensingState, dispatch: licensingDispatch} = useContext(LicensingContext)
  const {state:sessionState, dispatch: sessionDispatch} = useContext(SessionContext)
  const { state: helpState, dispatch: helpStateDispatch} = useContext(UserHelpContext);
  const { dispatch: rightPaneDispatch } = useContext(RightPaneContext)
  const { dispatch: appMessageDispatch } = useContext(AppMessagesContext)

  const { dispatch: windowDispatch } = useWindowContext();

  useEffect(() => {
    if (props.show) {
      const closeOutside = (ev: PointerEvent) => {
        const elem = document.getElementById(burgerMenuId)
        if (elem?.contains(ev.target as HTMLElement)) {
          return
        }
        rightPaneDispatch(actionToggleBurgerMenu(false))
        document.removeEventListener('pointerdown', closeOutside)
      }
      document.addEventListener('pointerdown', closeOutside);
      () => document.removeEventListener('pointerdown', closeOutside)
    }
  },[props.show])


  if (!props.show) {
    return <></>
  }

  return (<div className='BurgerMenu' id={burgerMenuId}>
      {/* <div className="BurgerHeader">Menu</div> */}
      <LicenseStateCompact />
      <LanguageSelector />
    <div className='scroll'>
      <ProtectedFeature feature={Feature.BNBOToolAdmin} contentsIfNoAccess={<></>} >
        <BurgerBtn icon={<Convert />}  onClick={()=>{windowDispatch(openWindow(WindowId.BNBODialog))}} text={"BNBO Admin"} />
      </ProtectedFeature>
      <BurgerBtn icon={<BsInfo />} onClick={()=>{windowDispatch(openWindow(WindowId.InformationWindow));}} text={Localization.getText("Info Page")} />
      <ProtectedFeature feature={Feature.GettingStarted} contentsIfNoAccess={<></>}>
        <BurgerBtn icon={<BsQuestion />} onClick={()=>{
          const contactInformation = SettingsManager.getSystemSetting("contactInformation");
          const linkToManual = contactInformation && contactInformation.linkToManual ? contactInformation.linkToManual : "";
          window.open(linkToManual, '_blank')!.focus();
        }}text={Localization.getText("Getting Started")} />
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.GettingStarted} contentsIfNoAccess={<></>}>
        <BurgerBtn icon={<BsQuestion />} onClick={()=>helpStateDispatch(showReleases())}text={Localization.getText("Welcome to Mapit")} />
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.ShowReleases} contentsIfNoAccess={<></>}>
        <BurgerBtn icon={<BsQuestion />} onClick={()=>helpStateDispatch(showUserGuide())}text={Localization.getText("Releases")} />
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SaveMap} contentsIfNoAccess={<></>}>
        <BurgerBtn icon={<BsSave />} onClick={() => windowDispatch(openWindow(WindowId.SaveMapDialog))} text={Localization.getText("Save map")} />
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SaveMapCloud} contentsIfNoAccess={<></>}>
        <BurgerBtn icon={<BsCloudUpload />} onClick={() => windowDispatch(openWindow(WindowId.CloudSaveDialog))} text={Localization.getText("Save map on Cloud")} />
      </ProtectedFeature>
        <ProtectedFeature feature={Feature.SaveLink} contentsIfNoAccess={<></>}>
        <BurgerBtn icon={<BsLink45Deg />} onClick={() => {
          Cookies.getCookieBoolean(MitCookies.RememberMapLinkGDPRAccept) ? windowDispatch(openWindow(WindowId.SaveLinkDialog)) : windowDispatch(openWindow(WindowId.GDPRNoticeApproval, {
            cookie: MitCookies.RememberMapLinkGDPRAccept,
            acceptCallback: () => windowDispatch(openWindow(WindowId.SaveLinkDialog))
          }));
          }} text={Localization.getText("Generate map link")} />
      </ProtectedFeature>





      {/* <BurgerBtn 
            onClick={()=> {mapitStateDispatch(actionSetShowWindow(MapitWindowId.MapSettingsEditor, true))}}
            icon={<BsMap />}
            text={Localization.getText("Menuitem:MapSettings")} /> */}

          <BurgerBtn 
            onClick={()=> {windowDispatch(openWindow(WindowId.UserSettingsEditor))}}
            icon={<BsGear />}
            text={Localization.getText("Menuitem:UserSettings")} />
            
          <ProtectedFeature feature={Feature.SettingsCustomer} contentsIfNoAccess={<></>} >
          <BurgerBtn 
            onClick={()=> {windowDispatch(openWindow(WindowId.CustomerSettingsEditor))}}
            icon={<BsGearWide />}
            text={Localization.getText("Menuitem:CustomerSettings")} />
          </ProtectedFeature>                    
          <BurgerBtn icon={<BsLock />} onClick={() => sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.ChangePassword))} text={Localization.getText("Change Password")} />
          <BurgerBtn icon={<BsXSquare />} onClick={() => sessionDispatch({type: SessionActionType.Logoff ,payload: {  userName: sessionState.userName}})} text={Localization.getText("Log Off")} />

          <ProtectedFeature feature={Feature.Debugging} contentsIfNoAccess={<></>}>
                <BurgerBtn icon={<AiFillBug />} onClick={() => appMessageDispatch(actionSetInfoMessage("Double klick to remove license"))} onDoubleClick={()=>{
                  licensingDispatch(actionDisableLicense(sessionState.customerRef, sessionState.userRef, licensingState.currentlicense!, () => {
                    alert(`License ${licensingState.currentlicense!.product+":"+licensingState.currentlicense!.productVariant}removed for user ${sessionState.userName}. Logging off`);
                  }));
                  sessionDispatch(actionLogoff(sessionState.userRef));
                }} text="Remove License" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { licensingDispatch(actionSimulateExpiredLicense())}} text="Sim Expired License" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { licensingDispatch(actionSimulateInvalidLicense())}} text="Sim Invalid License" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { console.log(mapitState.layers)}} text="Log Layers" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { console.log(mapitState.featureLayers)}} text="Log featuresLayers" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { console.log(mapitState.layerHierarchy)}} text="Log layerHierarchy" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { console.log(bnboState)}} text="Log BNBO" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { windowDispatch(openWindow(WindowId.CompanyInformationDialog, {cvrNr:25483839}))}} text="Show Company (25483839)" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { windowDispatch(openWindow(WindowId.PersonInformationDialog, {name:"Test", dateOfBirth: new Date("1-1-2000")}))}} text="Show Person (Test)" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { windowDispatch(openWindow(WindowId.MapLinkOverview))}} text='Show Saved Maps' />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { appMessageDispatch(actionSetProgressMessage("Text: Set Progress 0", 0));setTimeout(() => appMessageDispatch(actionClearProgressMessage()),10000) }} text="Set Progress 0" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { appMessageDispatch(actionSetProgressMessage("Text: Set Progress 33", 33));setTimeout(() => appMessageDispatch(actionClearProgressMessage()),10000) }} text="Set Progress 33" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { appMessageDispatch(actionSetProgressMessage("Text: Set Progress 50", 50));setTimeout(() => appMessageDispatch(actionClearProgressMessage()),10000) }} text="Set Progress 50" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { appMessageDispatch(actionSetProgressMessage("Text: Set Progress 100", 100));setTimeout(() => appMessageDispatch(actionClearProgressMessage()),10000) }} text="Set Progress 100" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { appMessageDispatch(actionSetInfoMessage("Text: SetInfoMessage")) }} text="SetInfoMessage" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { appMessageDispatch(actionSetErrorMessage("Text: SetErrorMessage")) }} text="SetErrorMessage" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { MitStorage.clearAll() }} text="Clear LocalStorage" />
                  <BurgerBtn icon={<AiFillBug />} onClick={() => { MitStorage.getKeys().filter((a) => a.startsWith("ADM-Pos-")).forEach((a) => MitStorage.clearValue(a))}} text="Clear Positions" />
                

               <BurgerBtn icon={<AiFillBug />} onClick={() => { windowDispatch(openWindow(WindowId.LoadedSettings))}} text="Show Loaded Settings" />
               <BurgerBtn icon={<AiFillBug />} onClick={() => { windowDispatch(openWindow(WindowId.UserAccessRights))}} text="Show Access Rights" />
               <BurgerBtn icon={<AiFillBug />} onClick={() => { windowDispatch(openWindow(WindowId.SimulateOtherUser))}} text="Simulate Other User (beta)" />
               {Object.keys(licenseVariantToRoles).map((a) => {
              return (<Fragment key={a}>
                <BurgerBtn icon={<AiOutlineUserSwitch />} text={"Switch to "+a} onClick={() => licensingDispatch(actionSwapLicenseVariant(a))} />
              </Fragment>)
            })}
          </ProtectedFeature>



    </div>
  </div>)
}


function CatchmentSideBar() {
  const {state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext)
  const {state: catchmentState, dispatch: catchmentStateDispatch} = useContext(CatchmentStateContext);
  const {hasAccessToFeature,} = useContext(ApplicationStateContext);
  const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
  const { state: windowState, dispatch: windowDispatch } = useWindowContext();


  function isWindowActive(a:WindowId) {
    if (windowState.find((e) => e.id === a)) {
      return true
    }
    return false
  }

  return (
  <CatchmentSelector
        showWindow={Boolean(isWindowActive(WindowId.CatchmentSelector))}
        showHideButton={true}
        showDownloadButton={hasAccessToFeature(Feature.TravelTimeDownloadPolygons)}
        showRushHourSelector={hasAccessToFeature(Feature.TravelTimeRushHourMode)}
        showCombinedBikeAndTransit={hasAccessToFeature(Feature.TravelTimeCombinedBikeAndTransit) && catchmentState.calculationMode === CalculationMode.Simple_RegenerateAfterEachParameterChange}
        isCollapsed={false}
        onChange={(modeOfTransport:string, rushHourMode:boolean) => {
          let newState:CatchmentState = {...catchmentState, selectedModeOfTransport:modeOfTransport, rushHourMode:rushHourMode};
          catchmentStateDispatch(actionSetCatchmentState(newState))
          if (catchmentState.calculationMode === CalculationMode.Simple_RegenerateAfterEachParameterChange) {
            catchmentStateDispatch(actionGenerateCatchmentSimple(newState , mapitState, catchmentState.catchmentLocation!));
          } else {
            windowDispatch(openWindow(WindowId.PointDistanceTable, {dialogMode:"Polygons"}))
            windowDispatch(closeWindow(WindowId.CatchmentSelector))
          }
        }}
        hideCatchments={() => {
          // Hide any polygon layer shown
          catchmentState.layerHandle && mapitState.map.removeLayerByHandle(catchmentState.layerHandle as any);
          windowDispatch(closeWindow(WindowId.CatchmentSelector))
          // clear any cached polygons. To allow an easy way of making sure that new User Settings - affecting polygons - have effect the next time the dialog is used.
          CatchmentInterface.clearCache();
        }}
        onDownloadCatchments={() => { 
          if (catchmentState.polygons) {
          let json = JSON.stringify(catchmentState.polygons);
          let blob = new Blob([json], {type: "application/json"});
          let fileName="catchment.json";
          ExportData.downloadBlob(fileName, blob);
          } else {
            appMessageDispatch(actionSetErrorMessage("No catchment to download"));
          }
        }}
        onSaveCatchments={() => {
          const createAsSingleLayer:boolean = false;
          if (createAsSingleLayer) {
            let newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureCollection(catchmentState.polygons, Localization.getText("Travel Time"));
            mapitStateDispatch(actionAddDataLayer(newLayerInfo));
          } else {
            let layerInfos = (catchmentState.polygons! as any).features.map((ft) => {
              let timeSeconds = ft.properties.time;
              let idx = catchmentState.travelTimes.findIndex((val) => val === timeSeconds);
              let color = catchmentState.colorScale[idx];
              let rushHourText = catchmentState.rushHourMode ? ","+Localization.getText("Rush hour"):"";
              let motText = Localization.getText("ModeOfTransport:"+catchmentState.selectedModeOfTransport || "");
              let layerName = Localization.getFormattedText("Travel Time {mot} ({time} mins)",{mot:motText+rushHourText, time:Math.floor(timeSeconds/60)});
              let newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeature(ft, layerName);
              // set opacity to 0.7
              newLayerInfo.styling.color = chroma(color).alpha(0.7).css();
              newLayerInfo.styling.lineOpacity = 0;
              return newLayerInfo;
            }) as LayerInfo[]
            windowDispatch(openWindow(WindowId.NameOnCreation, {layers: layerInfos})) 
            catchmentState.layerHandle && mapitState.map.removeLayerByHandle(catchmentState.layerHandle as any);
            windowDispatch(closeWindow(WindowId.CatchmentSelector));
            windowDispatch(closeWindow(WindowId.PointDistanceTable))
          }
        }}
        onToggleShowItemCounts={()=>{
          let newState:CatchmentState = {...catchmentState, showSpatialItemsCounts:!(catchmentState.showSpatialItemsCounts)};
          catchmentStateDispatch(actionSetCatchmentState(newState))
        }}
      />
  )
}
