

import * as turf from '@turf/turf';
import {
   ProjectionType,
   ColorRange,
   LayerInfoStyling,
   DataDivisionList,
   SizeRange,
   LabelButton,
   ViewerState
} from '../common/managers/Types';
import {PointMetaData} from '../managers/GenerateGeomUtils';
import {GeoJsonObject} from 'geojson';
import {Utils} from '@viamap/viamap2-common';
import { MapFacadeMaplibre, MitLayerHandleMaplibre } from './MapFacadeMaplibre';
import { FeatureLayer } from './WmsLayerFunc';
import { VectorLayer } from './VectorLayerFunc';

export type MitEvent = any;
export type MitMap = MapFacadeAbstract;
export enum MapType {Leaflet, Mapbox, Maplibre, Openlayers, Undefined}
// export type MitBounds = number[];
export type MitId = string;



type MitHandleLayer = {
   id:string
}

export const PriotisedMapLayerTypes = [
   "pie",
   "symbol",
   "circle",
   "fill-extrusion",
   "fill-text",
   "line",
   "grid",
   "fill",
   "heatmap",
   "raster"
] as const

export type MapLayerType = typeof PriotisedMapLayerTypes[number]
export type MapLayerTypeAndSubType = `${MapLayerType}-${string}`
export type AllowedMapLayerType = MapLayerType | MapLayerTypeAndSubType

export function getMitLayerHandle():MitLayerHandle {
   return new MitLayerHandleMaplibre()
}

export abstract class MitLayerHandle {
   source:any;
   sourceId:string="";
   layerList:MitHandleLayer[]=[];
   // layersConsumingEvents:MitHandleLayer[]=[];
   layersWithPieCharts:string[]=[];
   
   abstract hasItems():boolean;
   abstract hasLayers():boolean;
   abstract addlayersWithPieCharts(sourceId: string)
   abstract addSource(id:string, source: any)
   abstract addLayer(layer: any)
   abstract addLayerConsumingEvents(layerId: string)
   abstract getLayersConsumingEvents():string[]
   abstract remove();
   abstract clearLayers(map:MitMap);
   abstract moveLayer(map:MitMap);
   abstract addTo(map:MitMap);
   abstract addTo(map:MitMap, before: MitLayerHandle[]);
}

export class MitLatLng {
   lat:number=0;
   lng:number=0;
   alt:number=0;

   static fromL(latLng:{lat:number, lng:number}):MitLatLng {
      return new MitLatLng(latLng.lat, latLng.lng);
   }

   static fromM(lngLat:{lat:number, lng:number}):MitLatLng {
      return new MitLatLng(lngLat.lat, lngLat.lng);
   }
   
   constructor(latitude: number, longitude: number, altitude?: number) {
      this.lat=latitude;
      this.lng = longitude;
      this.alt= altitude || 0;
   }

   getLngLat():any {
      return {lat:this.lat,lng:this.lng,alt:this.alt};
   }

   getLatLng():any {
      return {lat:this.lat,lng:this.lng,alt:this.alt};
   }
   distanceTo(point:MitLatLng) {
      let a = turf.point([this.lng, this.lat]);
      let b = turf.point([point.lng, point.lat])
      return turf.distance(a,b);
   }

   get2dArr():[number,number] {
      return [this.lat,this.lng]
   }
}

export abstract class MapFacadeAbstract {

   abstract ControlPosition:{
      TopRight:string, 
      TopLeft:string, 
      BottomRight:string, 
      BottomLeft:string
   };
   abstract getCenter():MitLatLng;
   abstract isInitialized():boolean;
   abstract remove():void;
   abstract zoomToFeature(target:any):void;
   // abstract initMap(id:any, isOrthophotoGlobal?: boolean):void;
   abstract addZoomControl():void;
   abstract getZoomControl():string;
   abstract addScaleControl():void;
   abstract addAttribution(attr:{}):void;
   abstract setBackgroundLayer(layerSpec:string):void;
   abstract newPoiLayer():MitLayerHandle;
   abstract on(event:string, handler:(e:MitEvent)=> void):void;
   abstract on(event:string, layerId, handler:(e:MitEvent)=> void):void;
   abstract off(event:string, handler:(e:MitEvent)=> void):void;
   abstract off(event:string, layerId, handler:(e:MitEvent)=> void):void;
   abstract once(event:string, handler:(e:MitEvent)=> void):void;
   abstract once(event:string, layerId, handler:(e:MitEvent)=> void):void;
   abstract getZoom():number;
   abstract setMinZoom(zoom:number);
   abstract setMaxZoom(zoom:number);
   abstract removeLayer(layer:MitLayerHandle);
   abstract addLayer(layerHandle:MitLayerHandle)
   abstract addLayer(layerHandle:MitLayerHandle, beforeLayerHandles:MitLayerHandle[])
   abstract updateLayer(layer:MitLayerHandle);
   abstract createCircle(latlng:MitLatLng, radius:number):void;
   abstract getPoints():MitLatLng[];
   abstract renderCatchments(result:any, travelTimes2:number[], colorScale2:any[]):void;
   abstract easeTo(options: any, eventData?: any):void;
   abstract locate():void;
   abstract removeLayerByHandle(layer:MitLayerHandle):void;
   abstract setLayoutProperty(layer:MitLayerHandle, property);
   abstract setPaintProperty(layer:MitLayerHandle, style);
   abstract getLayerVisibility(layer:MitLayerHandle):boolean;
   abstract setLayerVisibility(layer:MitLayerHandle, visible:boolean):void;
   abstract hasImage(id:string) 
   abstract loadImage(url: string, callback: any)
   abstract addImage(id: string, image: any)
   abstract setViewerState(newState:{}):void;
   abstract getBounds():any;
   abstract getBearing():number;
   abstract getPitch():number;
   abstract setData(layer:MitLayerHandle, geoJson:any):void
   /**
    * @deprecated stop using this
    */
   abstract getMapPop():maplibregl.Map
   abstract getCanvas():HTMLCanvasElement
   abstract queryRenderedFeatures(geometryOrOptions?, options?):any
   abstract hideCatchments();
   abstract setFeatureState(feature, state);
   abstract zoomToPoint(latlng:MitLatLng):void;
   abstract getCircleSelector(      
      mapObj:MitMap, 
      callbackOnComplete:(latlng:MitLatLng, distance:number)=>void,    
      callbackOnMove:(latlng:MitLatLng, distance:number)=>void,
      callbackOnCancel:()=>void,
      DefaultDistance:number, 
      MinimumDistance:number, 
      MaximumDistance:number
      ):CircleSelectorAbstract;
   // abstract getGenerateGeom():GenerateGeomInterface;
   abstract initializeBackgroundLayers();
   abstract addVectorLayer(layer: VectorLayer):MitLayerHandle;
   abstract updateVectorLayer(layer: VectorLayer, value: {[key:string]:any}):void;
   abstract removeVectorLayer(layer: VectorLayer):void;
   abstract addFeatureLayer(key:string, layerSpec:FeatureLayer):MitLayerHandle;
   abstract updateFeatureLayer(key: string, layerSpec: FeatureLayer):void;
   abstract removeFeatureLayer(key:string):void;
   abstract getLatLngFromMouseEvent(clickEvent:any):MitLatLng;
   abstract setUrlToLabels(urlList: {urlIdent:string, label:string,x:number,y:number}[]):void;
   abstract readLabelFromUrl(urlIdent:string):{urlIdent:string, label:string,x:number,y:number};
   
   // abstract fitBounds(bounds:MitBounds)
   abstract showUserLocation(show:boolean):void;
   abstract registerMoveendCallbackHandler(moveendHandler: (viewerState:ViewerState) => void):void;
}

export abstract class CircleSelectorAbstract {
   abstract enable():void;
   abstract disable():void;
   abstract isDisabled():boolean;
   abstract cleanup():void;
   abstract remove():void;
   abstract show(latlng?:MitLatLng, radius?:number):void;
   abstract hide():void;
   abstract getLatLng():MitLatLng|undefined;
}

export interface GenerateGeomInterface {

   constructPopupLabelsAndButtons(
      index: number,
      lat: number,
      lng: number,
      labelButtonList: LabelButton[],
      getLabelHtml?: (index: number) => string,
      geometry?: any
   ):HTMLElement ;
   
   constructPopupLabelsAndButtonsGeoJSON(
      geometry:any,
      getLabelHtml?:(index:number)=>string,
      callbackOnTravelTimeButton?:(latlng:MitLatLng)=>void,
      callbackOnSpatialSelectionButton?:(latlng:MitLatLng)=>void
   ):HTMLElement;

      
   showLayerMarkerSimple(
      layerUniqueName:string,
      map:MitMap, 
      projectionType:ProjectionType, 
      coord1:any[], 
      coord2:any[],
      marker:string,
      useCustomIcon:boolean,
      labelButtonList: LabelButton[],
      customIconUrl?:string[],
      getLabelHtml?:(index:number)=>string,
      useClustering?:boolean
  ):MitLayerHandle ;

  
  showLayerMarkerByValue(
   layerUniqueName:string,
   map:MitMap, 
   projectionType:ProjectionType, 
   coord1:any[], 
   coord2:any[], 
   defaultColor:string,
   sizeByValue: boolean, 
   colorByValue: boolean,
   styling: LayerInfoStyling,
   labelButtonList: LabelButton[],
   colors?:ColorRange,
   sizes?:SizeRange, 
   value?:any[], 
   valueSize?:any[],
   divisions?:DataDivisionList,
   divisionsSize?:DataDivisionList,
   getLabelHtml?:(index:number)=>string,
   useClustering?:boolean
):MitLayerHandle ;

showLayerArea(
   layerUniqueName:string,
   v2:GeoJsonObject, 
   map:MitMap, 
   admGeo:any[], 
   value:any[], 
   title:string, 
   colors:ColorRange, 
   divisions:DataDivisionList, 
   hideNoDataAreas:boolean, 
   styling:LayerInfoStyling, 
   findKeyIndexByFeature:(codes:any[], jsonFeature:any) => number,
   getLabelHtml?:(index:number, jsonFeature:any)=>string,
   callbackOnSpatialSelection?:(metaData:{}[])=>any
):MitLayerHandle ;


showLayerGrid(
   layerUniqueName:string,
   map:MitMap, 
   projectionType:ProjectionType, 
   coord1:any[], 
   coord2:any[], 
   sizeM:any[],
   dataQuality:any[], 
   colors:ColorRange, 
   colorByValue:boolean,
   styling: LayerInfoStyling,
   labelButtonList: LabelButton[],
   value?:any[], 
   divisions?:DataDivisionList,
   getLabelHtml?:(index:number)=>string
):MitLayerHandle ;


   showLayerGeoJSON(
      layerUniqueName:string,
      v2:GeoJsonObject, 
      map:MitMap, 
      markerStyle:any, 
      featureStyle:any, 
      crs?:number, 
      getLabelHtml?:(jsonFeature:any)=>string, 
      callbackOnSpatialSelection?:(metaData:{}[])=>any)
   :MitLayerHandle ;

   AsyncDisplayPOIforBounds(mapObj:MitMap, poiLayer:MitLayerHandle, poiList:string[]);

   setupBaseData(map:MitMap);

   getNextColor():string;

   spatialSelection(mapobj:MitMap, center:MitLatLng, radiusMeters:number):PointMetaData[];

}

export class MapFacadeFactory {
   private static map:MapFacadeAbstract;
   static getMapFacade(containerElement:HTMLDivElement): MitMap {
      containerElement.innerText = "";
      let map = MapFacadeFactory.map;
      // if (!map) {
         // if (Utils.getSystemSetting("Mapsystem","leaflet") === 'mapbox') {
            map = new MapFacadeMaplibre(containerElement);
      //    } else {
      //       map = new MapFacadeLeaflet();
      //    }
      //    MapFacadeFactory.map = map;
      // }
      return map;
   }

   static getMapType(): MapType {
      let result = MapType.Undefined;
      // if (MapFacadeFactory.map instanceof MapFacadeLeaflet) {
      //    result = MapType.Leaflet;
      // } else {
      //    if (MapFacadeFactory.map instanceof MapFacadeMapbox) {
            result = MapType.Mapbox;
      //    }
      // }
      return result;
   }
}
